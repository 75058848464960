import PropTypes from 'prop-types';

// @mui
import {useState } from 'react';
import {InputAdornment,TextField } from '@mui/material';
// components
import Iconify from '../../../components/iconify';



BlogPostsSearch.propTypes = {
  posts: PropTypes.array.isRequired,
};

export default function BlogPostsSearch(props) {

  const [searchKeyword, setSearchKeyword] = useState('');

  const handleSearchKeyword = (theKeyword) => {
    setSearchKeyword(theKeyword);
  }

  const submitAndSearch = () => {
    if(searchKeyword === ''){
      window.alert("Key word can't be empty.Please try again.");
    } else {
        props.handleSearchingPost(searchKeyword);
    }
    
  }

  return (

        <TextField
          size="small"
          placeholder="Search courses..."
          onChange={(e) => handleSearchKeyword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Iconify 
                style={{
                  width:"30px",
                  height:"30px",
                  padding:"4px",
                  cursor:"pointer",
                  backgroundColor:"#f9fafb",
                  borderRadius:"12px"}}
                  onClick={submitAndSearch}
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#d4d9de"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "#f9fafb"}
                icon={'ion:paper-plane'} 
                sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
              </InputAdornment>
            )
          }}
        />
  );
}
