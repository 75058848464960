import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import BlogPage from 'src/pages/BlogPage';
import EditPage from 'src/pages/EditPage';
import { WindowOutlined } from '@mui/icons-material';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});


// Function to get all category IDs including subcategories
function getCategoryIds(categoriesTree, categoryId) {
  const categoryIds = [];

  // Helper function to recursively traverse the tree and collect category IDs
  function traverseCategories(category) {
    // Add the current category ID if it matches or is a child of the matching category
    categoryIds.push(category.id);

    // Continue traversing subcategories
    if (category.children) {
      category.children.forEach(subcategory => {
        traverseCategories(subcategory);
      });
    }
  }

  // Find the initial category and start traversal
  function findAndTraverse(category, categoryId) {
    if (category.id === categoryId) {
      traverseCategories(category);
    } else if (category.children) {
      category.children.forEach(subcategory => {
        findAndTraverse(subcategory, categoryId);
      });
    }
  }

  findAndTraverse(categoriesTree, categoryId);
  return categoryIds;
}


// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const location = useLocation();
  const scrollPositionRef = useRef(0);
  const [blogPosts, setBlogPosts] = useState([]);

  // Inside the <NavSection> users can directly click the top left button`new post` to add a new post under the 
  // root category,and the post's short description will be saved to backend server,after that,a new request will
  // be sent to backend sever to fetch the category tree again,so that the newly created post title can be seen 
  // immediately.
  //
  // And right after that, we need to open up the <Edit /> page for the user to finish the remaining parts of the
  // article.Since the user has already writen the title,we need to set it as the default value in the editing page.
  // So do the postId,Creation Date, category Id,etc.
  const [postTitle, setPostTitle] = useState('');
  const [postId, setPostId] = useState('');
  const [postDate, setPostDate] = useState('');
  const [categoryId, setCategoryId] = useState(0);
  // In order to show the editing page, we need to close up <BlogPage /> and the <Header />
  const [closeBlogAndOpenEditing, setCloseBlogAndOpenEditing] = useState(false);
  // But users can also click the Edit button up  on the post to open up the edit page,so we need an indicator
  // to tell <EditPage/> that this info comes from the <NavSection/>
  const [isFromNavSection,setIsFromNavSection] = useState(false);
  //
  // Next we need to create a function to set those values based on the data received from <NavSection/>
  const openEditingPageOnSavingNewPost = (postTitle, postId, postDate, categoryId) => {
    setPostTitle(postTitle);
    setPostId(postId);
    setPostDate(postDate);
    setCategoryId(categoryId);
    // window.alert("This message comes from the <DashBeardLayout /> component : " + postTitle +","+ postId +","+ postDate +","+ categoryId);
    // Once a new post in root category is added, we need to close the <BlogPage /> and the <Header />
    setCloseBlogAndOpenEditing(true);
    // And add additional info
    setIsFromNavSection(true);
  };


  // Event listener to monitor the refreshing behavior of the user
  // If the user refreshes the page, we delete the item noNeedToRefetch
  // So that the component will be forced to fetch all posts again.
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Clear the freshness flag on page refresh
      localStorage.removeItem('noNeedToRefetch');
      // Clear the scrolled position, once refreshed, we need to go back to the top
      localStorage.setItem('scrollPosition', 1);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleSaveScrolledPosition = () => {
    // console.log(window.scrollY);
    localStorage.setItem("scrollPosition", window.scrollY);
  };

  // useEffect(() => {
  //   // Restore scroll position when component mounts
  //   const savedScrollPosition = localStorage.getItem('scrollPosition');
  //   if (savedScrollPosition) {
  //     window.scrollTo(0, parseInt(savedScrollPosition, 10));
  //     console.log(parseInt(savedScrollPosition, 10));
  //     localStorage.removeItem('scrollPosition'); // Optionally, clear it after restoring
  //   }
  // }, [blogPosts]);

  // Restore scroll position when component mounts
  useEffect(() => {
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      scrollPositionRef.current = parseInt(savedScrollPosition, 10);
    }
  }, [blogPosts]);

  useEffect(() => {
    if (scrollPositionRef.current !== 0) {
      window.scrollTo(0, scrollPositionRef.current);
      scrollPositionRef.current = 0; // Reset after using
    }
  }, [blogPosts]);

  useEffect(() => {
    // console.log(location.state.clickedCategory);
    // window.alert("I'm the main page");
    
    // When coming back from the post detail page, we want to retain the previous posts and the clickedCategoryName
     
    // Each time the user refreshes the page, the localstorage will be cleared, and the user can get the latest data
    // from backend server
    const cachedPostsData = JSON.parse(localStorage.getItem("postsData"));
    const clickedCategory = localStorage.getItem("currentSelectedCategory");
    const noRefresh = localStorage.getItem("noNeedToRefetch");

    if(cachedPostsData && clickedCategory && noRefresh){
      setBlogPosts(cachedPostsData);
      setClickedCategoryName(clickedCategory);
    } else {

      let currentLanguage = "en";
      fetch(`${process.env.REACT_APP_API_URL}/posts/getAllPosts?language=${currentLanguage}`)
        .then(response => {
          if(!response.ok){
            throw new Error(`HTTP error! Status: ${response.status}`);
          } else {
            return response.json();
          }
        })
        .then(data => {
          setBlogPosts(data);
          // Cache all posts in local storage, so that we don't fetch post data too frequently
          localStorage.setItem("postsData", JSON.stringify(data));
          localStorage.setItem("currentSelectedCategory", "All-Courses");
          localStorage.setItem("noNeedToRefetch", "true");// Set the refreshnes flag
          console.log(data);
        })
        .catch(error => console.error('Error fetching posts: ', error));
    }
    
    
  } , []);

  const handleRefetchPostsAfterDelete = () => {
    let currentLanguage = "en";
    fetch(`${process.env.REACT_APP_API_URL}/posts/getAllPosts?language=${currentLanguage}`)
      .then(response => {
        if(!response.ok){
          throw new Error(`HTTP error! Status: ${response.status}`);
        } else {
          return response.json();
        }
      })
      .then(data => {
        setBlogPosts(data);
        // Cache all posts in local storage, so that we don't fetch post data too frequently
        localStorage.setItem("postsData", JSON.stringify(data));
        localStorage.setItem("currentSelectedCategory", "All-Courses");
        localStorage.setItem("noNeedToRefetch", "false");// Set the refreshnes flag
        console.log(data);
      })
      .catch(error => console.error('Error fetching posts: ', error));
  };


  const [open, setOpen] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState("");

  // Inside the <NavSection /> componet, when the user click on a specific post title, the corresponding 
  // post card will be fetched and show on the right panel.
  const handleCurrentPostTitleClick = (postId) => {
    const userAgent = window.navigator.userAgent;
    let platformDetail;
    let platform;
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      platformDetail = 'Apple Phone';
      platform = 'mobile';
    } else if (/Macintosh|Mac/i.test(userAgent)) {
      platformDetail = 'Apple Computer';
      platform = 'pc';
    } else if (/Android/i.test(userAgent)) {
      platformDetail = 'Android Phone';
      platform = 'mobile';
    } else if (/Windows/i.test(userAgent)) {
      platformDetail = 'Windows Computer';
      platform = 'pc';
    } else if (/Linux|Ubuntu|Manjaro|Nix|Fedora|Centos|Redhat|kodachi|FreeBSD/i.test(userAgent)) {
      platformDetail = 'Linux Computer';
      platform = 'pc';
    } else {
      platformDetail = 'Android Phone';
      platform = 'mobile';
    }

    fetch(`${process.env.REACT_APP_API_URL}/posts/` + postId + "?platform=" + platform + "&platformDetail=" + platformDetail)
      .then(response => {
        if(!response.ok){
          throw new Error("No Such Post Exists!");
        }
        return response.json();
      })
      .then(data => {
        setBlogPosts(data);
      })
      .catch(error => console.log(error))

  };

  // When user clicks a category, we need to display its name on the right panel to show the current posts' category name
  const [clickedCategoryName, setClickedCategoryName] = useState("All-Courses");

  // Function to get all category IDs including subcategories
  // const getCategoryIds = (categoriesTree, categoryId) => {
  //   const categoryIds = [];

  //   // Helper function to recursively traverse the tree and collect category IDs
  //   const traverseCategories = (category) => {
  //     // Add the current category ID if it matches or is a child of the matching category
  //     categoryIds.push(category.id);

  //     // Continue traversing subcategories
  //     if (category.children) {
  //       category.children.forEach(subcategory => {
  //         traverseCategories(subcategory);
  //       });
  //     }
  //   }

  //   // Find the initial category and start traversal
  //   const findAndTraverse = (category, categoryId) => {
  //     if (category.id == categoryId) {
  //       traverseCategories(category);
  //     } else if (category.children) {
  //       category.children.forEach(subcategory => {
  //         findAndTraverse(subcategory, categoryId);
  //       });
  //     }
  //   }

  //   findAndTraverse(categoriesTree, categoryId);
  //   return categoryIds;
  // }

  // Inside the <NavSection/> component, when the user clicks on a specific category name, the corressponding 
  // posts inside that category will be fetched from backend sever and show on the right panel,the <BlogPage />
  const handleCurrentCategoryId = (categoryId, clickedCategoryName) => {
    // Clear the scrolled position, once chose a category, we need to  start from the beginning, instead of some previously stored position.
    localStorage.setItem('scrollPosition', 1);// Notice:
    // 1.We can't simply remove it. Even though the blogPosts has been refreshed,since we fetched posts under that category the window.scrollTo() function won't be invoked because scrollPositionRef.current is set to 0 each time we scroll back to previous position.
    //
    // What that means is that the page will stay on this position forever, because there's no one to tell it to  change to the top.So we need to manually set it to 1.
    //
    // 2.So we need to set it to 1, so that when switching between different categories,we can always start from the beginning of the list.
    //
    // 3.The reason why we can directly remove this item instead of setting it to 1 is that we need to manually scroll to top in order to do the search thing, we don't need the window.scrollTo() to do it for us.


    const cachedPostsData = JSON.parse(localStorage.getItem("postsData"));
    const cachedCategoryData = JSON.parse(localStorage.getItem("categoriesTree"));

    if(cachedPostsData && cachedCategoryData && false){
      const targetCategoryIds = getCategoryIds(cachedCategoryData, categoryId);
      // console.log(targetCategoryIds);
      // post.categories is actually a string, so we need to do a conversion
      const postsInCategory = cachedPostsData.filter((post) => targetCategoryIds.includes(Number(post.categories)) );
      // console.log(postsInCategory);

      setBlogPosts(postsInCategory);
      setClickedCategoryName(clickedCategoryName);
    } else {
      let currentLanguage = "en";
      fetch(`${process.env.REACT_APP_API_URL}/posts/search/a/category/`+ categoryId + "?language=" + currentLanguage)
        .then(response => {
          if(!response.ok){
            throw new Error("Parent category clicked.Don't need to refresh blog page");
          }
          return response.json();
        })
        .then(data => {
          // window.alert(data);
          setBlogPosts(data);
          localStorage.setItem("postsData", JSON.stringify(data));
          localStorage.setItem("currentSelectedCategory", clickedCategoryName);
          // console.log(clickedCategoryName);
          setClickedCategoryName(clickedCategoryName);
          
        }) // use data.body instead of data
        .catch(error => console.log(error));
    }

  
    // 
    // setCurrentCategoryId(id);
    // window.alert(id);
  };

  const handleSearchingPost = (keyword) => {
    // Before displaying all search results, we need to clear the previously recorded scrolling postion
    localStorage.setItem('scrollPosition', 1);
    // The reason why we can directly remove this item instead of setting it to 1 is that we need to manually scroll to top in order to do the search thing, we don't need the window.scrollTo() to do it for us.
    // But the best practise is to set it to 1 to make sure the search results always starts from the top.


    // So that when displaying seach results, we want to start from the beginning instead of the middle position
    let formattedKeyword = keyword;
    let currentLanguage = "en";
    formattedKeyword = keyword.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");


    // if(currentLanguage === "en"){
    //   formattedKeyword = keyword.replace(/[^a-zA-Z0-9]+/g, "-");
    // } else if(currentLanguage === "jp"){
    //   formattedKeyword = keyword.replace(/[^\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF\uFF65-\uFF9Fa-zA-Z0-9]+/g, "-");
    // } else if(currentLanguage === "fr"){
    //   formattedKeyword = keyword.replace(/[^a-zA-ZàâäçéèêëîïôœùûüÿÀÂÄÇÉÈÊËÎÏÔŒÙÛÜŸ]+/g, "-");
    // } else {
    //   formattedKeyword = keyword.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]+/g, "-");
    // }
    fetch(`${process.env.REACT_APP_API_URL}/posts/search/entire/post/` + formattedKeyword + "?language=" + currentLanguage)
      .then(response => {
        if(!response.ok){
          return response.text();
        } else {
          return response.json();
        }
      })
      .then( data => {
        if(typeof(data) === 'string'){
          window.alert(data);
        } else {
          setBlogPosts(data);
          // If there are any searching results, we need to display the words :"Search + keyword"
          // to inform the user what they have searched
          setClickedCategoryName(`Search: ${keyword}`);
          // We also need to retain the search results so that the user can easily come back without searching again
          localStorage.setItem("postsData", JSON.stringify(data));
          localStorage.setItem("currentSelectedCategory", `Search: ${keyword}`);
        }
      })
      .catch(error => console.log(error));
  }

  const handleSortingBlogs = (sortingOrder) => {
    // window.alert(sortingOrder);
    if(sortingOrder === 'latest'){
      // sort in descending order
      blogPosts.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
    } else {
      // sort in ascending order
      blogPosts.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    }
  }

  return (
    <StyledRoot>
      {
        !closeBlogAndOpenEditing
        ? <Header 
            onOpenNav={() => setOpen(true)}
            style={{width:"100px"}} />
        : <></>
      }
       

      <Nav 
      openNav={open} 
      onCloseNav={() => setOpen(false)} 
      onClickCategory={handleCurrentCategoryId}
      onClickPostTitleInCategoryTree={handleCurrentPostTitleClick}
      handleRefetchPostsAfterDelete={handleRefetchPostsAfterDelete}
      openEditingPageOnSavingNewPost = {openEditingPageOnSavingNewPost}/>

      {/* <Main>
        <Outlet />
      </Main> */}

      {
        !closeBlogAndOpenEditing
        ? <BlogPage 
          key={blogPosts.length} 
          blogPosts={blogPosts} 
          saveScrolledPosition={handleSaveScrolledPosition}
          currentCategoryName={clickedCategoryName}
          handleKeywordSearch={handleSearchingPost}
          handleSortingBlogs={handleSortingBlogs}/>
        : <></>
      }

      {
        closeBlogAndOpenEditing
        ? <EditPage
            postTitle={postTitle}
            postId={postId}
            postDate={postDate}
            categoryId={categoryId}
            isFromNavSection={isFromNavSection}
            onOpenNav={() => setOpen(true)}
          />
        : <></>
      }
      

    </StyledRoot>
  );
}
