import * as React from 'react';
import { useEffect, useState,useRef } from "react";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import  TextField  from '@mui/material/TextField';
// The following icons can be found on the material ui official site.
// link : https://mui.com/material-ui/material-icons/
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
// import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SortIcon from '@mui/icons-material/Sort';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useParams,useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import WbSunnyIcon from '@mui/icons-material/WbSunny';



const data = {
    id: 'root',
    name: 'Categories',
    subtotal: '1329',
    children: [
      {
        id: '11',
        name: 'Programming Languages',
        subtotal: '120',
        children: [
          {
            id: '110',
            name: 'All',
            subtotal: '20'
          },
          {
            id: '111',
            name: 'Java',
            subtotal: '10'
          },
          {
            id: '112',
            name: 'Python',
            subtotal: '50'
          },
          {
            id: '113',
            name: 'Web DEV',
            subtotal: '13'
          },
          {
            id: '114',
            name: 'Go Lang',
            subtotal: '27'
          }
        ]
      },
      {
        id: '12',
        name: 'Databases',
        subtotal: '200',
        children: [
          {
            id: '120',
            name: 'All',
            subtotal: '20'
          },
          {
            id: '121',
            name: 'MySQL',
            subtotal: '120'
          },
          {
            id: '122',
            name: 'MongoDB',
            subtotal: '60'
          }
        ]
      },
      {
        id: '13',
        name: 'Web Development',
        subtotal: '123',
        children: [
          {
            id: '130',
            name: 'All',
            subtotal: '23'
          },
          {
            id: '131',
            name: 'ReactJS',
            subtotal: '50'
          },
          {
            id: '132',
            name: 'MaterialUI',
            subtotal: '20'
          },
          {
            id: '133',
            name: 'HTML',
            subtotal: '30'
          }
        ]
      },
      {
        id: '14',
        name: 'Tech Career',
        subtotal: '234'
      },
      {
        id: '17',
        name: 'Programming Tools',
        subtotal: '332',
        children: [
          {
            id: '170',
            name: 'All',
            subtotal: '32'
          },
          {
            id: '171',
            name: 'Git',
            subtotal: '100'
          },
          {
            id: '172',
            name: 'Maven',
            subtotal: '100'
          },
          {
            id: '173',
            name: 'Docker',
            subtotal: '30'
          },
          {
            id: '174',
            name: 'ChatGPT',
            subtotal: '40'
          },
          {
            id: '175',
            name: 'Anaconda',
            subtotal: '30'
          }
        ]
      },
      {
        id: '15',
        name: 'Resources',
        subtotal: '3'
      },
      {
        id: '16',
        name: 'About',
        subtotal: '8'
      }
    ],
  };

  const TigerButton = styled(Button)({
    fontSize: 10
  });

export default function NavSection(props) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [categories, setCategories] = useState({});
  let rootIdArray = [];

  // On the top left corner ,users can click the third button to change the sorting order of files in the category
  const [showChangeSortOrderContextMenu, setShowChangeSortOrderContextMenu] = useState(false);

  // Once we get the category tree from backend server, we need to add the id of the root category to this array
  // This root id is only used for adding new root categories by pressing the `New Folder` button on the page
  let rootId = [];

  // On the category tree, users can right click a category to delete the category and all posts in it
  // Once click `Delete`,a dialog will show up,we set up the following state variable to toggle open it or not
  const [openConfirmDeleteCategoryDialog, setOpenConfirmDeleteCategoryDialog] = useState(false);
  // Then we need to get the currently being right clicked category's id and proceed to the deleting process
  const handleDeleteCategoryAndPostsInside = () => {
    // First close up the dialog
    setOpenConfirmDeleteCategoryDialog(false);
    // Second proceed to delete the category
    // window.alert("Id of the category to be deleted is : " + idOfRightClickedCategory);
    fetch(`${process.env.REACT_APP_API_URL}/categories/delete/category/by/` + idOfRightClickedCategory)
      .then(response => {
        if(!response.ok) {
          console.log(response.text());
          throw new Error(t('ErrorDeletingCategory'));
        }
        return response.text();
      })
      .then(data => {
        // Update posts on the right panel
        props.handleRefetchPostsAfterDelete();

        // Update category tree on the left
        // update the category tree
        fetch(`${process.env.REACT_APP_API_URL}/categories/get/all/categories`)
          .then(response => response.json())
          .then(data => {
           

            // Since we've setCategories(data); data has been saved to the local state variable,we can directly use 
            // categories.id to fetch the root id,don't need to maintain the rootId array.
            rootId.push(data.id);
            setCategories(data);

             // When we hit the top left `new folder` button, we'll be redicted to this function to add a new 
             // category for the root category,since it's root category , it should be opened up by default
             // so we don't need to change anything about the expanding thing,let it keep open.
             //
             // But there's one special accassion that the root folder is collpased by the user,and at this tiem
             // we need to add the id manually to make it open,so that the newly-added category can be seen.but 
             // we don't use the following function,this function is only for mouse clicks,if the user clicks one 
             // of the categories,open it up,if click again,close it,which is obviously not what we want here.
            // handleExpandingCategory(data.id);
            setExpandedIdArray([...expandedIdArray, categories.id]);
            

            // Now we've gotten category data,set the category tree to true to show it up
            setIsCategoryEmpty(false);
            // We also need to close the current TextField
            setOpenNewRootCategory(false);
          })
          .catch(error => console.log(error));

        window.alert(data);
        // navigate(`/`);// refreshing the page doesn't work, we need to manually refetch posts
        
      })
      .catch(error => console.log(error))
  };

  // On the category tree, when the user tries to rename a post title through the right click,a text field will show up
  const [openRenamePostTitle, setOpenRenamePostTitle] = useState({});
  // When the user types in the new name, its value will be synced with a state variable below
  const [newNameForPost,setNewNameForPost] = useState('');
  // Once confirmed, the following function will be triggerred
  const handleRenamePostOnRightClick = () => {
    const postId = currentIdOfRightClickedPost;
    const postNewName = newNameForPost;
    const originalExpandedIdArray = expandedIdArray;

    const payload = {
      "postId" : postId,
      "postTitle" : postNewName
    };

    const requestOptions = {
      method: "POST",
      headers: {"Content-Type" : "application/json"},
      body: JSON.stringify(payload)
    };

    // window.alert("post to be deleted : id -> " + postIdToRename + "new name : " + postNewName);
    fetch(`${process.env.REACT_APP_API_URL}/categories/rename/a/post`, requestOptions)
      .then(response => {
        if(!response.ok){
          console.log(response.text());
          throw new Error(t('ErrorRenamingPostTitle'));
        }
        return response.text();
      })
      .then(data => {
        window.alert(data);

       navigate(`/`);
       setExpandedIdArray(originalExpandedIdArray);
      })
      .catch(error => console.log(error));

  };

  // On the category tree, if the user tries to delete a post by selecting the `Delete` option from the context
  // menu after right clicked the post title,a confirmation dialog will show up and only when the user clicks
  // Confirm will the post and its translations be deleted
  // 
  // Since we'll use the postId to delete it,we need to create a state variable to store the currently being 
  // right clicked post title's id
  const [currentIdOfRightClickedPost, setCurrentIdOfRightClickedPost] = useState('');
  // We also need to set up a state variable for toggling on and off the confirmation dialog
  const [openDeletePostDialog, setOpenDeletePostDialog] = useState(false);
  // Once the user clicks the confirm button, the following function will be triggerred and delete the post
  const handleDeletingPostFromCategoryTree = () => {
    let currentLanguage = "en";
    // We first try to delete the post and all its translations
    fetch(`${process.env.REACT_APP_API_URL}/posts/deleteBy/` + currentIdOfRightClickedPost + "?language=" + currentLanguage)
      .then(response => response.text())
      .then(data => {
        props.handleRefetchPostsAfterDelete();

        // update the category tree
        fetch(`${process.env.REACT_APP_API_URL}/categories/get/all/categories`)
          .then(response => response.json())
          .then(data => {
           

            // Since we've setCategories(data); data has been saved to the local state variable,we can directly use 
            // categories.id to fetch the root id,don't need to maintain the rootId array.
            rootId.push(data.id);
            setCategories(data);

             // When we hit the top left `new folder` button, we'll be redicted to this function to add a new 
             // category for the root category,since it's root category , it should be opened up by default
             // so we don't need to change anything about the expanding thing,let it keep open.
             //
             // But there's one special accassion that the root folder is collpased by the user,and at this tiem
             // we need to add the id manually to make it open,so that the newly-added category can be seen.but 
             // we don't use the following function,this function is only for mouse clicks,if the user clicks one 
             // of the categories,open it up,if click again,close it,which is obviously not what we want here.
            // handleExpandingCategory(data.id);
            setExpandedIdArray([...expandedIdArray, categories.id]);
            

            // Now we've gotten category data,set the category tree to true to show it up
            setIsCategoryEmpty(false);
            // We also need to close the current TextField
            setOpenNewRootCategory(false);
          })
          .catch(error => console.log(error));


        window.alert(data);
        // navigate(`/`);
      })
      .catch(error => console.log(error));
    // If everything's ok,we close the dialog
    setOpenDeletePostDialog(false);
  };

  const isMobile = window.innerWidth <= 650;
  // The value of variableNavWidth passed from the parent component can be negative or greater than 4000 or even greater
  // We don't want that kind of value to be passed in,so we filter out those values and only keep its value
  // if it's from 300 to 600
  const xIndicator = props.variableNavWidth < 300 ? 300 : props.variableNavWidth > 600 ? 600 : props.variableNavWidth;
  
  // On the main category tree,there are two button,one for collpase all,one for expand all,but we only show one of them
  // at a time,and once we click the button,it should be changed to another one,and vice verse.So we set up a variable
  // to toggle the visibility of the two buttons.
  // 
  // Originally,only the first layer of the main category tree is opened,so we set the default value of 
  // showExpandAllIcon to true,because we want to display that icon first.
  const [showExpandAllIcon, setShowExpandAllIcon] = useState(false);

  // Originally,there's no category data,when the user clicks `new folder`,we toggle openNewRootCategory on and
  // show the corressponding TextField
  const [openNewRootCategory, setOpenNewRootCategory] = useState(false);
  //
  // Inside the TextField that is responsible for typing in the content of the root category,
  // we'll keep track of the contents,if it's empty or only contains white spaces,
  // set the Confirm button disabled.
  const [newRootCategoryContent, setNewRootCategoryContent] = useState('');

  // In the top left corner of the page, user can click `New Post` to add a new post to the root category
  // we set up NewPostTitleInRootCategory to store the title
  // and OpenNewPostInRootCategory to toggle open the <TextField> or not
  const [openNewPostInRootCategory, setOpenNewPostInRootCategory] = useState(false);
  const [newPostTitleInRootCategory, setNewPostTitleInRootCategory] = useState('');

  // Inside the child category tree,when the user right click on a child category and chooses `new foler` option
  //  from the context menu,we'll set this up to toggle open up or close the corressponding <TextField>
  const [showTextFieldOnAddingCategory,setShowTextFieldOnAddingCategory] = useState({});
  // And inside that <TextField> we'll use newCategoryName to keep track of the input value
  const [newCategoryName,setNewCategoryName] = useState({});


  // Inside the child category tree, when the user right click on a child category and chooses `new post` option
  // from the cnotext menu, we'll set this up to toggle open up or close the corressponding <TextField>
  const [showTextFieldOnAddingPost, setShowTextFieldOnAddingPost] = useState({});
  // And inside that <TextField> we'll use newPostTitle to keep track of the input value
  const [newPostTitle, setNewPostTitle] = useState({});

  // const [currentLanguage,setCurrentLanguage] = useState("");

  // When the user right clicks a category in the category tree, the contextMenu will show up and let the user
  // select some options.The showContextMenu sets the visibility of the context menu,and the contextMenuPosition
  // sets the position of the context menu
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useState(false);
  // But when the user right clicks on a post title,we don't want to pop up the same context menu,we want another
  // with only two options: rename and delete
  const [postContextMenuPosition, setPostContextMenuPosition] = useState({ x: 0, y: 0 });
  const [showPostContextMenu, setShowPostContextMenu] = useState(false);

  // Each category in the category tree has a <TextField> to type in information such as new category name
  // rename,etc.And that <TextField> won't show up until the user right click a category and select 
  // `New Category`.But because of the rendering logic of the <TreeView> and <TreeItem> ,the current value of
  // the `nodes.id` inside the renderTree() function is always the id of the newest opened category.When we click
  // a category to show its children,we can say that category is opened.
  // 
  // By default,the `defaultExpanded` property of `<TreeView>` is set to the id of the root category,so that,by 
  // default,the root category is opened,and we can see a list of its children.Right now ,if we right click any 
  // of those children and select `New Category` in the pop-up menu,only the <TextField> of the root category will
  // show up.
  //
  // What we want is not the `nodes.id`,the opened category's id,what we want is the currently being right clicked
  // category's id.And use that id to set the value of
  // `showTextFieldOnAddingCategory`
  // and 
  // `newCategoryName`
  const [idOfRightClickedCategory, setIdOfRightClickedCategory] = useState('');

  // On the category tree, users can right click and select `Rename` to rename it and then the following function
  // will run and open up the <TextField> for typing the new name
  // But before that, we need to creat a state variable to toggle open the text field
  const [showTextFieldOnRenamingCategory, setShowTextFieldOnRenamingCategory] = useState({});
  // And also the state variable to hold the new name
  const [renameCategoryName, setRenameCategoryName] = useState({});
  // Finally let's do the function thing
  const handleOpenRenameCategoryTextField = () => {
    setShowContextMenu(false);
    // In order to show the <TextField> successfully,we need to firstly expand the category
    // if it hasn't.
    const updatedArray = expandedIdArray.includes()
      ? expandedIdArray
      : [...expandedIdArray, idOfRightClickedCategory];

    setExpandedIdArray(updatedArray);

     // Before opening up the <TextField>,we need to close up the previously opened one,
    // so that when adding a new category,only one <TextField> will be present.
    setShowTextFieldOnRenamingCategory({});

    // We also need to close the <TextField> for inputing new post title
    setShowTextFieldOnAddingPost({});
    // and the textfield for inputing new category name
    setShowTextFieldOnAddingCategory({});
    // And clear the contents inside it
    setNewPostTitle((prevState) => ({ ...prevState, [idOfRightClickedCategory]: '' }));
    setNewCategoryName((prevState) => ({ ...prevState, [idOfRightClickedCategory]: '' }));

    // Show the TextField for user to type the new category name
    setShowTextFieldOnRenamingCategory((prevState) => ({ ...prevState, [idOfRightClickedCategory]: !prevState[idOfRightClickedCategory] }));
      // Empty contents inside the TextField
    setRenameCategoryName((prevState) => ({ ...prevState, [idOfRightClickedCategory]: '' }));
  };
  // Once filled in the new name, and clicked the confirm button,we should proceed to rename the category
  const handleRenamingCategory = (categoryId, newName) => {
    let language = "en";
    

    const payload = {
      "categoryId" : categoryId,
      "categoryName" : newName,
      "language" : language
    };

    const requestOptions = {
      method: "POST",
      headers: {"Content-Type" : "application/json"},
      body: JSON.stringify(payload)
    };

    // window.alert("category id : " + categoryId + " and its new name : " + newName);
    fetch(`${process.env.REACT_APP_API_URL}/categories/rename/a/category`, requestOptions)
      .then(response => {
        if(!response.ok){
          console.log(response.text());
          throw new Error(t('ErrorRenamingCategory'));
        }
        return response.text();
      })
      .then(data => {
        window.alert(data);
        // Fetch the category again to reflect changes immediately
        fetch(`${process.env.REACT_APP_API_URL}/categories/get/all/categories`)
          .then((response) => response.json())
          .then((data) => {
            if(Object.keys(data).length === 0){
              // window.alert("Category tree empty.");
              // If it's an empty tree, don't show the category at all
              setIsCategoryEmpty(true);
            } else {
              // The following function controls wether to expand or collapse a category,and it now takes in the if of the
              // root category,since the component just loaded and after that, only the root category will be expanded
              // if exists,remove,if not,add it.
              handleExpandingCategory(data.id);

              // This array is only used for storing the root id
              rootId.push(data.id);
              setCategories(data);
              localStorage.setItem("categoriesTree",JSON.stringify(data));   
            }

          })
          .catch((error) => console.error(error));
      })
      .catch(error => console.log(error));

    // finally clear and close the text field
    setRenameCategoryName((prevState) => ({ ...prevState, [idOfRightClickedCategory]: '' }));
    setShowTextFieldOnRenamingCategory((prevState) => ({ ...prevState, [idOfRightClickedCategory]: !prevState[idOfRightClickedCategory] }));

  };



  // When we right click a category and select `New Category` in the menu,the <TextField> won't 
  // show up until the category is opened.But we manually open it up, the <TextField> won't show up
  // either,because we've set `setShowTextFieldOnAddingCategory({});` when clicking a category.
  //
  // To show the <TextField>,we can't open it manually,but let the <TreeView> component to open it .
  // So now we want to set up an array to store the ids of categories that have been right clicked
  // and set it to the `expand` property of <TreeView> to expand that category to show the <TextField>
  //
  const [expandedIdArray, setExpandedIdArray] = useState([]);
  // Once set,the default expanded root category will be no longer expanded by default,we need to add the
  // root id to this array to manually open it up.
  // Inside useEffect(),once the page is loaded,category data will be fetched from backend server and 
  // set to `categories` after converted into json format like the following : 
  //
  // {
  //   "subtotalfr": "2",
  //   "children": [...All its children are here...]
  //   "subtotal": "2",
  //   "name": "Categories",
  //   "id": "527",
  //   "subtotalcn": "2",
  //   "subtotaljp": "2"
  // }
  //
  // So we can directly use `categories.id` to get the id of the root category and push it into the `expandedIdArray`
  // in order to expand the root category by default just as before.And in order to add `categories.id` to the array,
  // we don't do it here,but in the useEffect() function.
  //
  // But that is just the special case,and it's only for initializing the look of the category tree,once it's loaded
  // users can choose to expand or collapse any categories as they'd like.We use the following function to achieve it.
  //
  // Initially,the `expandedIdArray` contains only one element,the id of the root.When the user clicks a category
  // if it's already in the array,that means the category has alrady been expanded,so we need to remove its id from 
  // `expandedIdArray` to make it collapsed.
  // if it's not,which means that the category is collapsed,we need to add its id to `expandedIdArray` to expand it.
  //
  const handleExpandingCategory = (categoryId) => {
    const updatedExpandedIdArray = expandedIdArray.includes(categoryId)
      ? expandedIdArray.filter((id) => id !== categoryId)
      : [...expandedIdArray, categoryId];

    setExpandedIdArray(updatedExpandedIdArray);
  };

  // If there's already a category tree, users can add posts inside child categories as well,but first , we need to open
  // up the <TextField> for the user to type in the post name.
  const handleOpenNewPostFromContextMenu = () => {
    // Once click on the `new post` button on the context menu,we can close it up
    setShowContextMenu(false);

    // In order to show the <TextField> successfully,we need to firstly expand the category
    // if it hasn't.
    const updatedArray = expandedIdArray.includes()
      ? expandedIdArray
      : [...expandedIdArray, idOfRightClickedCategory];

    setExpandedIdArray(updatedArray);

    // Before opening up the <TextField>,we need to close up the previously opened one,
    // so that when adding a new category,only one <TextField> will be present.
    setShowTextFieldOnAddingPost({});
    // Not only do we need to close up the previously opened one for typing in the post title,
    // As for the possible opened <TextField> for typing in the new category name,we need to close it , too
    setShowTextFieldOnAddingCategory({});
    // And if there's unsaved texts inside it, clear it too
    setNewCategoryName((prevState) => ({ ...prevState, [idOfRightClickedCategory] : '' }));

    // Show the TextField for user to type the post titleee
    setShowTextFieldOnAddingPost((prevState) => ({ ...prevState, [idOfRightClickedCategory]: !prevState[idOfRightClickedCategory] }));
    // Empty contents inside the TextField
    setNewPostTitle((prevState) => ({ ...prevState, [idOfRightClickedCategory]: '' }));
  }
  // After typing in the post title in the <TextField>,user can click Confirm to submit the title
  // handleAddingChildPost(nodes.id, newPostTitle[nodes.id]);}
  const handleAddingChildPost = (parentCategoryId, childPostTitle) => {

    // Before the postId is generated in SpringBoot according to the current timestamp,
    // now we move that part here,so that after saving the post short desc,we can use 
    // the postId to open up the  editing page.
    const childPostId = Date.now().toString();
    const createDateString = Date.now().toString();

    const payload = {
      "parentCategoryId" : parentCategoryId,
      "postId" : childPostId,
      "postTitle" : childPostTitle
    };

    const requestOptions = {
      method: "POST",
      headers: {"Content-Type" : "application/json"},
      body: JSON.stringify(payload)
    };

    fetch(`${process.env.REACT_APP_API_URL}/categories/save/new/posts`, requestOptions)
      .then(response => {
        if(!response.ok) {
          console.log("From SpringBoot : " + response.text());
          throw new Error(t('ErrorCreatingNewPost'));
        }
        return response.text();
      })
      .then(data => {
        // Once get the returned message,there's no need to pop it up,just print it out in the terminal
        console.log(data);
        // updata the category tree
        fetch(`${process.env.REACT_APP_API_URL}/categories/get/all/categories`)
          .then(response => response.json())
          .then(data => {
            // After adding the new category,we directly add its parent's id to the expandedIdArray to 
            // expand its parent,so that the newly-added category will be shown immediately
            setExpandedIdArray([...expandedIdArray, parentCategoryId]);

            // Since we've setCategories(data); data has been saved to the local state variable,we can directly use 
            // categories.id to fetch the root id,don't need to maintain the rootId array.
            // rootId.push(data.id);
            setCategories(data);
            // Now we've gotten category data,set the category tree to true to show it up
            setIsCategoryEmpty(false);

            // We also need to clear and close the current TextField for typing in the post title
            setNewPostTitle((prevState) => ({ ...prevState, [parentCategoryId]: '' }));
            setShowTextFieldOnAddingPost((prevState) => ({ ...prevState, [parentCategoryId]: !prevState}));

            // After successfully saved the post, we need to jump into the editing page 

          })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));

      // After successfully added the new post short desc into the database, we need to open up the editing page
      // for the user to continue his work
      // We've successfully gotten the updated category tree and showed it up,now we need to open up the
      // editing page for user to finish the remaining part of the article.And we need the following info:
      // postTitle, postId, postDate, categoryId
      props.handleAddedNewPostInRoot(childPostTitle, childPostId, createDateString, parentCategoryId);

  };

  // If there's alreay a category tree, users can add child categories as well
  const handleAddingChildCategory = (parentCategoryId, childCategoryName) => {
    let language = "en";

    const payload = {
      "categoryId" : parentCategoryId,
      "categoryName" : childCategoryName,
      "language" : language
    };

    const requestOptions = {
      method: "POST",
      headers: {"Content-Type" : "application/json"},
      body: JSON.stringify(payload)
    };

    fetch(`${process.env.REACT_APP_API_URL}/categories/save/new/categories`, requestOptions)
      .then(response => {
        if(!response.ok){
          // window.alert(idOfRootCategory + "and rootId : " + categories.id);
          console.log("From SpringBoot: " + response.text());
          throw new Error(t('ErrorCreatingNewCategory'));
        }
        return response.text();
      })
      .then(data => {
        // When creating new categories,don't pop up messages,print it in the console instead.
        console.log(data);
        // update the category tree
        fetch(`${process.env.REACT_APP_API_URL}/categories/get/all/categories`)
          .then(response => response.json())
          .then(data => {
            // After adding the new category,we directly add its parent's id to the expandedIdArray to 
            // expand its parent,so that the newly-added category will be shown immediately
            setExpandedIdArray([...expandedIdArray, parentCategoryId]);

            // Since we've setCategories(data); data has been saved to the local state variable,we can directly use 
            // categories.id to fetch the root id,don't need to maintain the rootId array.
            rootId.push(data.id);
            setCategories(data);
            // Now we've gotten category data,set the category tree to true to show it up
            setIsCategoryEmpty(false);

            // We also need to clear and close the current TextField
            setNewCategoryName((prevState) => ({ ...prevState, [parentCategoryId]: '' }));
            setShowTextFieldOnAddingCategory((prevState) => ({ ...prevState, [parentCategoryId]: !prevState}));
          })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));
  };

  // users can click the top left `new post` icon to create new post under root category
  const handleAddingNewPostInRootCategory = () => {
    let idOfRootCategory;
    const generatedPostId = Date.now().toString();
    const creationDateString = Date.now().toString();

    // If the returned data is an empty map,we can't get the id of root ,but the backend server does require that one
    // So we'll fake one.Don't worry,if there's no data in the database, the backend will ignore the fake id,so it
    // won't cause any trouble.
    if(categories?.id === undefined){
      idOfRootCategory = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    } else {
      idOfRootCategory = categories.id;
    }

    const payload = {
      "parentCategoryId" : idOfRootCategory,
      "postId" : generatedPostId,
      "postTitle" : newPostTitleInRootCategory
    };

    const requestOptions = {
      method: "POST",
      headers: {"Content-Type" : "application/json"},
      body: JSON.stringify(payload)
    };

    fetch(`${process.env.REACT_APP_API_URL}/categories/save/new/posts`, requestOptions)
      .then(response => {
        if(!response.ok){
          console.log("From SpringBoot: " + response.text());
          throw new Error(t('ErrorCreatingNewCategory'));
        }
        return response.text();
      })
      .then(data => {
        // Don't need to pop it up,save the message in the terminal is enough
        console.log(data);
        // update the category tree
        fetch(`${process.env.REACT_APP_API_URL}/categories/get/all/categories`)
          .then(response => response.json())
          .then(data => {
            // Since we've setCategories(data); data has been saved to the local state variable,we can directly use 
            // categories.id to fetch the root id,don't need to maintain the rootId array.
            // rootId.push(data.id);
            setCategories(data);
            props.handleAddedNewPostInRoot(newPostTitleInRootCategory, generatedPostId, creationDateString, data.id);
             // When we hit the top left `new post` button, we'll be redicted to this function to add a new 
             // post for the root category,since it's root category , it should be opened up by default
             // so we don't need to change anything about the expanding thing,let it keep open.
             //
             // But there's one special accassion that the root folder is collpased by the user,and at this tiem
             // we need to add the root id manually to make it open,so that the newly-added post can be seen.but 
             // we don't use the following function,this function is only for mouse clicks,if the user clicks one 
             // of the categories,open it up,if click again,close it,which is obviously not what we want here.
            // handleExpandingCategory(data.id);

            // This is the original expansion logic before 2024-05-17
            setExpandedIdArray([...expandedIdArray, categories.id]);
            



          

    

            // Now we've gotten category data,set the category tree to true to show it up
            setIsCategoryEmpty(false);
            // We also need to clear and close the current TextField
            setNewPostTitleInRootCategory('');
            setOpenNewPostInRootCategory(false);

           
          })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));

      // if(categories?.id === undefined){
      //   idOfRootCategory = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      // } else {
      //   idOfRootCategory = categories.id;
      // }
    // We've successfully gotten the updated category tree and showed it up,now we need to open up the
    // editing page for user to finish the remaining part of the article.And we need the following info:
    // postTitle, postId, postDate, categoryId
    // props.handleAddedNewPostInRoot(newPostTitleInRootCategory, generatedPostId, creationDateString, idOfRootCategory);
  }
  
  // Users can click the top left `new folder` icon to create new root folders
  const handleAddingNewCategory = () => {
    let idOfRootCategory;

    // If the returned data is an empty map,we can't get the id of root ,but the backend server does require that one
    // So we'll fake one.Don't worry,if there's no data in the database, the backend will ignore the fake id,so it
    // won't cause any trouble.
    if(categories?.id === undefined){
      idOfRootCategory = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    } else {
      idOfRootCategory = categories.id;
    }

    let language = "en";

    const payload = {
      "categoryId": idOfRootCategory,
      "categoryName" : newRootCategoryContent,
      "language" : language
    };

    const requestOptions = {
      method: "POST",
      headers: {"Content-Type" : "application/json"},
      body: JSON.stringify(payload)
    };

    fetch(`${process.env.REACT_APP_API_URL}/categories/save/new/categories`, requestOptions )
      .then(response => {
        if(!response.ok){
          // window.alert(idOfRootCategory + "and rootId : " + categories.id);
          console.log("From SpringBoot: " + response.text());
          throw new Error(t('ErrorCreatingNewCategory'));
        }
        return response.text();
      })
      .then(data => {
        // When creating new categories,don't pop up messages,print it in the console instead.
        console.log(data);
        // update the category tree
        fetch(`${process.env.REACT_APP_API_URL}/categories/get/all/categories`)
          .then(response => response.json())
          .then(data => {
           

            // Since we've setCategories(data); data has been saved to the local state variable,we can directly use 
            // categories.id to fetch the root id,don't need to maintain the rootId array.
            rootId.push(data.id);
            setCategories(data);

             // When we hit the top left `new folder` button, we'll be redicted to this function to add a new 
             // category for the root category,since it's root category , it should be opened up by default
             // so we don't need to change anything about the expanding thing,let it keep open.
             //
             // But there's one special accassion that the root folder is collpased by the user,and at this tiem
             // we need to add the id manually to make it open,so that the newly-added category can be seen.but 
             // we don't use the following function,this function is only for mouse clicks,if the user clicks one 
             // of the categories,open it up,if click again,close it,which is obviously not what we want here.
            // handleExpandingCategory(data.id);
            setExpandedIdArray([...expandedIdArray, categories.id]);
            

            // Now we've gotten category data,set the category tree to true to show it up
            setIsCategoryEmpty(false);
            // We also need to close the current TextField
            setOpenNewRootCategory(false);
          })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));
  };


  const handleRightClick = (event, node) => {
    // window.alert("The id of the category being right clicked is : " + node.id);
    // window.alert("variableWidth : " + props.variableNavWidth + "xIndicator : " + xIndicator);

    setIdOfRightClickedCategory(node.id);
    event.preventDefault();
      // Stop the event from propagating to the parent TreeItem,
      // In react, when right clicking a child category in the category tree,the `onContextMenu` event of all the
      // ancestors are also triggered,causing multiple calls to `handleRightClick` funtion and occasionaly open up
      // the wrong <TextField> in the wrong place.
    event.stopPropagation();

      // // Get the category tree container
      // const categoryTreeContainer = document.getElementById("category-tree-container");
      // if (!categoryTreeContainer){
      //   window.alert("category-tree-container not available");
      //   return;
      // } 

      // // Calculate the correct y position based on the scroll position of the container
      // const containerBottom = categoryTreeContainer.getBoundingClientRect().bottom;
      // const scrollY = window.scrollY || window.pageYOffset;
      // const offsetY = event.clientY + scrollY - containerBottom;
 
      // Get the right-clicked category node's position on the page
      // const categoryNodeElement = event.currentTarget;
      // const nodeRect = categoryNodeElement.getBoundingClientRect();

      // // Calculate the correct x and y positions for the context menu
      // const offsetX = nodeRect.left;
      // const offsetY = nodeRect.bottom + 10;
   

      // setContextMenuPosition({
      //   x: Math.max(Math.min(event.clientX, xIndicator - 170),60),
      //   y: offsetY,
      // });

      // Get the root category node's element
      const rootCategoryNodeElement = document.getElementById("root-category-node"); // Assuming you have set an ID for the root category node

      // Get the right-clicked category node's element
      const categoryNodeElement = event.currentTarget;

      // Get the positions of the two elements relative to the viewport
      const rootCategoryRect = rootCategoryNodeElement.getBoundingClientRect();
     
      const categoryNodeRect = categoryNodeElement.getBoundingClientRect();


      // Calculate the position difference between the two elements
      const offsetX = categoryNodeRect.left - rootCategoryRect.left;
      // const offsetY = categoryNodeRect.bottom - rootCategoryRect.top + 10;

      // Because the position of the context menu is relative to its parent container: the category tree.
      // But getBoundingClientRect() funtion only returns coordinates relative to the current viewport,that's to
      // say the current browser window.
      //
      // After getting the current position of the right-clicked node's top edge via categoryNodeRect.top
      // that value only defines the position of the current top edge of the node to the browser's top edge,
      // in order to make the context menu appear at this position,we need to convert that value to the one that's
      // realtive to the root node,the category tree.
      // 
      // That's why we need to const offsetY = categoryNodeRect.top - rootCategoryRect.top + 20;
      //
      // The value of window.innerHeight is the height of the browser,and when your browser is in full screen,this
      // value is the height of your monitor,when it's windowed,the height is the browser's height.
      let offsetY;
      if(isMobile) {
        offsetY = window.innerHeight - categoryNodeRect.top < 210 ? (categoryNodeRect.top - 185) : (categoryNodeRect.top + 24);
      }else {
        offsetY = window.innerHeight - categoryNodeRect.top < 210 ? (categoryNodeRect.top - rootCategoryRect.top + 24) : (categoryNodeRect.top - rootCategoryRect.top + 224);
      }
      
      // window.alert("Root : top " + rootCategoryRect.top + ",bottom " + rootCategoryRect.bottom + "Node : top " + categoryNodeRect.top + ",bottom " + categoryNodeRect.bottom + " ViewPort Height : " + window.innerHeight + "offsetY : " + offsetY);
     
      
      setContextMenuPosition({
        x: Math.max(Math.min(event.clientX + 10, xIndicator - 170),60),
        y: offsetY,
      });


    // Original version
    // setContextMenuPosition({
    //     // x: Math.min(event.clientX,130),
    //     // x: event.clientX,
    //     // x: Math.max(Math.min(Math.min(props.variableNavWidth - 160,430),event.clientX),60),
    //     // x: props.variableNavWidth - 160 > event.clientX ? event.clientX : props.variableNavWidth - 160,
    //     x: Math.max(Math.min(event.clientX, xIndicator - 170),60),
    //     y: event.clientY + 10
    //   });

    // Optionally, you can store the right-clicked node information if needed
    // setSelectedNode(node);

    // Finally show the context menu,but right before that, we need to first close up the possible opened
    // context menu for post titles
    setShowPostContextMenu(false);
    setShowContextMenu(true);
  };

  // When the user clicks on the `expand all` button, add all children's ids into the expandedIdArray
  // to expand all of them.
  // 

  const handleExpandingAllNodes = () => {
    // We first set up a temporary variable to store those Ids
    const tempIds = [];

    // Then define a function to recursively add each id into the tempIds array
    const traverseCategory = (rootCategory) => {
      tempIds.push(rootCategory.id);
      if(rootCategory.children && rootCategory.children.length > 0) {
        rootCategory.children.forEach((child) => {
          traverseCategory(child);
        });
      }
    }

    // Next execute the funtion
    traverseCategory(categories);

    // Finally set the expandedIds array
    setExpandedIdArray(tempIds);

  };

  const handleCloseContextMenu = () => {
    setShowContextMenu(false);
  };

  const [isCategoryEmpty, setIsCategoryEmpty] = useState(false);

  // We need to store the current language string in order to conditionally render the category names in
  // different languages,set it to English by default;
  const [currentLanguageString, setCurrentLanguageString] = useState("en");

  useEffect(() => {

    setCurrentLanguageString("en");
    // Deprecated API : http://localhost:8081/category/get/categories
    fetch(`${process.env.REACT_APP_API_URL}/categories/get/all/categories`)
      .then((response) => response.json())
      .then((data) => {
        if(Object.keys(data).length === 0){
          // window.alert("Category tree empty.");
          // If it's an empty tree, don't show the category at all
          setIsCategoryEmpty(true);
        } else {
          // The following function controls wether to expand or collapse a category,and it now takes in the if of the
          // root category,since the component just loaded and after that, only the root category will be expanded
          // if exists,remove,if not,add it.
          // This is the old logic for expand the root category by default before 2024-05-17
          // handleExpandingCategory(data.id);
          // Right now we want to expand all nodes by default, since 2024-05-17
          // We first set up a temporary variable to store those Ids
          const tempIds = [];

          // Then define a function to recursively add each id into the tempIds array
          const traverseCategory = (rootCategory) => {
            tempIds.push(rootCategory.id);
            if(rootCategory.children && rootCategory.children.length > 0) {
              rootCategory.children.forEach((child) => {
                traverseCategory(child);
              });
            }
          }

          // Next execute the funtion
          traverseCategory(data);

          // Finally set the expandedIds array
          setExpandedIdArray(tempIds);



          // This array is only used for storing the root id
          rootId.push(data.id);
          setCategories(data);
          localStorage.setItem("categoriesTree",JSON.stringify(data));   
        }

      })
      .catch((error) => console.error(error));
  }, []);

  const handleCategoryClick = (categoryId, categoryName) => {
    // During our tests using the following statement, we now know that post titles added to the category tree
    // are not tree nodes at all,when we click on it the window didn't even pop up.
    //
    // That's good news,because we can better distinguish left clicks between categories and post titles
    // window.alert("The id of the category being left clicked is : " + categoryId);
    props.onClickCategory(categoryId, categoryName);

    // console.log(categoryName);

    // For mobile devices, we need to close the current menu list once a category is clicked
    if(isMobile){
      props.onCloseNav();
    }
  };

  // For post titles in the category tree, once we click on it , it will be shown on the right panel,and we can click 
  // the post card again to view the post detail or edit it.
  const handlePostTitleClickInsideFolder = (postId) => {
    props.onClickPostTitle(postId);
  };


// As for the editing of the category,we now only support English
// Because we're using elasticsearch ,and it has the best support for English
  const renderTree = (nodes) => {
    return (
              <div>
                <TreeItem 
                  // defaultCollapseIcon={<ExpandMoreIcon />}
                  // defaultExpandIcon={<ChevronRightIcon />} Array.isArray(nodes.children)
                  // startIcon={<ChevronRightIcon />}
                  // collapseIcon={ Array.isArray(nodes.children) ? <ExpandMoreIcon /> : null}
                  // expandIcon={ Array.isArray(nodes.children) ? <ChevronRightIcon /> : null}
                  // nodes.id === '527' ? null : 
                  id={nodes.id === categories.id ? "root-category-node" : ""}
                  collapseIcon={<ExpandMoreIcon />}
                  expandIcon={<ChevronRightIcon />}
                  key={nodes.id} 
                  nodeId={nodes.id} 
                  label={ 
                    localStorage.getItem("userLoggingInfo")
                        ? nodes.name
                        : (
                            nodes.subtotal === 0 
                            ? nodes.name
                            : nodes.name + '    ('+ nodes.subtotal + ')'
                          )
                    }
                  onClick={() => {
                    // add the category id to `expandedIdArray` to expand on this category
                    // 2024-05-17:we don't need the category to expand or collapse anymore, we just want to show all categories.So that's why we comment off the following function.
                    // handleExpandingCategory(nodes.id);
                    // window.alert(nodes.id);
                    // Fetch post data using category id
                    // During the test period,we temperararily close it up.
                    handleCategoryClick(nodes.id, nodes.name);

                    // Close the contex menu for category,hang on a second , don't forget about the post titles' context menu
                    setShowContextMenu(false);
                    // Close the context menu for the post titles
                    setShowPostContextMenu(false);

                    // As for comments of the post, we can open up multiple <TextField> to post comments
                    // But for adding a new category,we don't want that.
                    // If the user successfully opens a <TextField> for typing in the new category name
                    // but the user didn't type in anything and wants to open up another category,we
                    // need to close this <TextField>
                    // So that in the entire category tree, only one editable <TextField> will be availble
                    setShowTextFieldOnAddingCategory({});
                  }}
                  {
                    ...(localStorage.getItem('userLoggingInfo') 
                        ? {onContextMenu : (event) => handleRightClick(event, nodes)}
                        : {})
                  }
                  
                  style={{
                    // border:"1px solid red",backgroundColor:"#edeff1",
                    margin:"5px",
                    color:"#798793",
                    borderRadius:"10px"
                  }}>
                      {/* Render the context menu */}
                        <div
                          style={{
                            display: showContextMenu ? "block" : "none",
                            position: 'absolute',
                            left: contextMenuPosition.x,
                            top: contextMenuPosition.y,
                            zIndex: 99999,
                            backgroundColor: '#e8edf1',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                            borderRadius: '4px',
                            padding: '8px',
                            width: '151px',
                            borderRadius: "10px"
                          }}
                        >
                          {/* Add your context menu options as buttons */}
                          <Button
                           onClick={() => {
                            setShowContextMenu(false);
                            // In order to show the <TextField> successfully,we need to firstly expand the category
                            // if it hasn't.
                            const updatedArray = expandedIdArray.includes(idOfRightClickedCategory)
                              ? expandedIdArray
                              : [...expandedIdArray, idOfRightClickedCategory];

                            setExpandedIdArray(updatedArray);

                            // Before opening up the <TextField>,we need to close up the previously opened one,
                            // so that when adding a new category,only one <TextField> will be present.
                            setShowTextFieldOnAddingCategory({});
                            // We also need to close the <TextField> for inputing new post title
                            setShowTextFieldOnAddingPost({});
                            // And clear the contents inside it
                            setNewPostTitle((prevState) => ({ ...prevState, [idOfRightClickedCategory]: '' }));

                            // Show the TextField for user to type the category name
                            setShowTextFieldOnAddingCategory((prevState) => ({ ...prevState, [idOfRightClickedCategory]: !prevState[idOfRightClickedCategory] }));
                            // Empty contents inside the TextField
                            setNewCategoryName((prevState) => ({ ...prevState, [idOfRightClickedCategory]: '' }));
                           }}
                           style={{borderRadius:"10px",margin:"3px 0px"}}
                           sx={{
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              left: 8,
                              bottom: 0,
                              width: '86%',
                              borderBottom: '3px solid #919eab',
                              borderRadius: '20px 20px 20px 20px', // Only the top corners are not rounded
                            },
                           }}
                           startIcon={<CreateNewFolderIcon />}
                          >
                           {t('NewFolder')}
                          </Button>
                          <Button
                           onClick={handleOpenNewPostFromContextMenu}
                           style={{borderRadius:"10px",margin:"3px 0px"}}
                           sx={{
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              left: 8,
                              bottom: 0,
                              width: '86%',
                              borderBottom: '3px solid #919eab',
                              borderRadius: '20px 20px 20px 20px', // Only the top corners are not rounded
                            }, 
                           }}
                           startIcon={<PostAddIcon />}
                           >
                            {t('NewPost')}
                          </Button>
                          <Button
                           onClick={() => {
                            handleCloseContextMenu();
                            handleOpenRenameCategoryTextField();
                           }}
                           style={{borderRadius:"10px",margin:"3px 0px"}}
                           sx={{
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              left: 8,
                              bottom: 0,
                              width: '86%',
                              borderBottom: '3px solid #919eab',
                              borderRadius: '20px 20px 20px 20px', // Only the top corners are not rounded
                            }, 
                           }}
                           startIcon={<DriveFileRenameOutlineIcon />}
                          >
                            {t('Rename')}
                          </Button>
                          <Button
                           onClick={() => {
                            // to delete a category,we first close the current context menu
                            setShowContextMenu(false);
                            // then pop up the confirmation dialog
                            setOpenConfirmDeleteCategoryDialog(true);
                            // If the user clicks confirm on that dialog,continue deleting category and posts in it
                           }}
                           style={{borderRadius:"10px",margin:"3px 0px"}}
                           sx={{
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              left: 8,
                              bottom: 0,
                              width: '86%',
                              borderBottom: '3px solid #919eab',
                              borderRadius: '10px 10px 10px 10px', // Only the top  corners are not rounded
                            },
                           }}
                           startIcon={<DeleteSweepIcon />}
                           >
                           {t('Delete')}
                          </Button>
                        </div>
                 
                        <Dialog
                          open={openConfirmDeleteCategoryDialog}
                          onClose={() => {setOpenConfirmDeleteCategoryDialog(false);}}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            Delete Category
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                             Are you sure to delete this category?All posts and their translations will be deleted as well.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => {setOpenConfirmDeleteCategoryDialog(false);}}>Cancel</Button>
                            <Button onClick={handleDeleteCategoryAndPostsInside} autoFocus>
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
                    {/* {
                      localStorage.getItem('userLoggingInfo') && nodes.name != "All"
                      ? isMobile
                        ? <div style={{display:"flex",border:"solid red 1px"}}>
                            <TigerButton
                            variant="outlined"
                            onClick={() => {
                              // window.alert("The clicked category name is : " + nodes.name + "And its id is : " + nodes.id);
                              // Show the TextField for user to type the category name
                              setShowTextFieldOnAddingCategory((prevState) => ({ ...prevState, [nodes.id]: !prevState[nodes.id] }));
                              // Each time when user clicks the Add button,clear the original one.
                              setNewCategoryName((prevState) => ({ ...prevState, [nodes.id]: '' }));
                            }}
                            >
                              Add
                            </TigerButton>
                            <TigerButton
                            variant="outlined"
                              size="small"
                              >
                              Rename
                            </TigerButton>
                            <TigerButton
                            variant="outlined"
                              size="small"
                              >
                              Delete
                            </TigerButton>
                          </div> 
                        : <div style={{display:"flex",border:"solid red 1px"}}>
                            <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setShowTextFieldOnAddingCategory((prevState) => ({ ...prevState, [nodes.id] : !prevState[nodes.id] }));
                              setNewCategoryName((prevState) => ({ ...prevState, [nodes.id] : '' }));
                            }}
                            >
                              Add
                            </Button>
                            <Button
                            variant="outlined"
                              size="small"
                              >
                              Rename
                            </Button>
                            <Button
                            variant="outlined"
                              size="small"
                              >
                              Del
                            </Button>
                          </div> 
                      : <></>
                    } */}
                     {
                      showTextFieldOnRenamingCategory[nodes.id]
                        ? <div style={{width:xIndicator * 0.85,display: xIndicator > 566 ? "flex" : ""}}>
                            <TextField 
                              autoFocus
                              size="small"
                              id="outlined-textarea"
                              label={t('RenameCategory')}
                              // placeholder={t('NoSpecialCharacters')}
                              variant="outlined"
                              style={{marginTop:"5px",width:xIndicator * 0.6}}
                              value={renameCategoryName[nodes.id]}
                              onChange={(e) => {
                                setRenameCategoryName((prevState) => ({ ...prevState, [nodes.id]: e.target.value }));
                              }}
                              />
                            <div style={{width:"170px"}}>
                              <Button
                                variant="contained"
                                style={{fontSize:"10px",margin:"8px"}}
                                onClick={() => {
                                  // clear the contents and close the <TextField>
                                  setRenameCategoryName((prevState) => ({ ...prevState, [nodes.id]: '' }));
                                  setShowTextFieldOnRenamingCategory((prevState) => ({ ...prevState, [nodes.id]: !prevState }));
                                }}
                              >
                                {t('cancel')}
                              </Button>
                              <Button
                                disabled={renameCategoryName[nodes.id].trim() === ''}
                                variant="contained"
                                style={{fontSize:"10px",margin:"8px 3px"}}
                                onClick={() => {handleRenamingCategory(nodes.id, renameCategoryName[nodes.id]);}}
                              >
                                {t('confirm')}
                              </Button>
                            </div>
                          </div>
                        : <></>
                    }

                    {
                      showTextFieldOnAddingPost[nodes.id]
                        ? <div style={{width:xIndicator * 0.85,display: xIndicator > 566 ? "flex" : ""}}>
                            <TextField 
                              autoFocus
                              size="small"
                              id="outlined-textarea"
                              label={t('AddNewPost')}
                              // placeholder={t('NoSpecialCharacters')}
                              variant="outlined"
                              style={{marginTop:"5px",width:xIndicator * 0.6}}
                              value={newPostTitle[nodes.id]}
                              onChange={(e) => {
                                setNewPostTitle((prevState) => ({ ...prevState, [nodes.id]: e.target.value }));
                              }}
                              />
                            <div style={{width:"170px"}}>
                              <Button
                                variant="contained"
                                style={{fontSize:"10px",margin:"8px"}}
                                onClick={() => {
                                  // clear the contents and close the <TextField>
                                  setNewPostTitle((prevState) => ({ ...prevState, [nodes.id]: '' }));
                                  setShowTextFieldOnAddingPost((prevState) => ({ ...prevState, [nodes.id]: !prevState }));
                                }}
                              >
                               {t('cancel')}
                              </Button>
                              <Button
                                disabled={newPostTitle[nodes.id].trim() === ''}
                                variant="contained"
                                style={{fontSize:"10px",margin:"8px 3px"}}
                                onClick={() => {handleAddingChildPost(nodes.id, newPostTitle[nodes.id]);}}
                              >
                               {t('confirm')}
                              </Button>
                            </div>
                          </div>
                        : <></>
                    }

                    {
                      showTextFieldOnAddingCategory[nodes.id]
                      ? <div style={{width:xIndicator * 0.85,display: xIndicator > 566 ? "flex" : ""}}>
                          <TextField 
                            autoFocus
                            size="small"
                            id="outlined-textarea"
                            label={t('AddNewCategory')}
                            // placeholder={t('NoSpecialCharacters')}
                            variant="outlined"
                            style={{marginTop:"5px",width:xIndicator * 0.6}}
                            value={newCategoryName[nodes.id]}
                            onChange={(e) => {
                              setNewCategoryName((prevState) => ({ ...prevState, [nodes.id]: e.target.value }));
                            }}
                            />
                           <div style={{width:"170px"}}>
                            <Button
                              variant="contained"
                              style={{fontSize:"10px",margin:"8px"}}
                              onClick={() => {
                                setNewCategoryName((prevState) => ({ ...prevState, [nodes.id]: '' }));
                                setShowTextFieldOnAddingCategory((prevState) => ({ ...prevState, [nodes.id]: !prevState }));
                              }}
                            >
                             {t('cancel')}
                            </Button>
                            <Button
                              disabled={newCategoryName[nodes.id].trim() === ''}
                              variant="contained"
                              style={{fontSize:"10px",margin:"8px 3px"}}
                              onClick={() => {handleAddingChildCategory(nodes.id, newCategoryName[nodes.id]);}}
                            >
                              {t('confirm')}
                            </Button>
                          </div>
                        </div>
                      : <></>
                    }
                   
                   {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTree(node))
                    : null}

                    {
                      localStorage.getItem("userLoggingInfo") && Array.isArray(nodes.postShortDesc) 
                        ? nodes.postShortDesc.map((post) => <div
                          key={post.postId}// Make sure to set a unique key for each post title
                          style={{
                            width:nodes.id === categories.id ? (xIndicator * 0.81) : (xIndicator * 0.65),
                            marginLeft: "18px",
                            marginTop: "10px",
                            backgroundColor: "#f4f8fb",
                            color: "#7f8c98",
                            fontSize: "15px",
                            borderRadius:"5px",
                            cursor: "pointer"
                          }}
                          
                          onClick={() => {
                            // Close the possibly opened context menu for the post titles
                            setShowPostContextMenu(false);
                            setShowContextMenu(false);
                            handlePostTitleClickInsideFolder(post.postId);

                          }}
                          onContextMenu={(event) => {
                            event.preventDefault();
                            // Stop the event from propagating to the parent TreeItem,
                            //
                            // In react, when right clicking a child category in the category tree,the `onContextMenu` 
                            // event of all the ancestors are also triggered,causing multiple calls to 
                            //`handleRightClick` funtion and occasionaly open up the wrong <TextField> in the wrong 
                            // place.
                            //
                            // Here,if we don't stop it, the `handleRightClick` function of parent node of this post 
                            // will also be triggered,which is not what we want.
                            event.stopPropagation();
                            // window.alert("Post being right clicked is : " + post.postId);
                            setCurrentIdOfRightClickedPost(post.postId);
                            // Update the context menu position according to the current mouse position
                            // setPostContextMenuPosition({
                            //   x: Math.max(Math.min(event.clientX, xIndicator - 170),60),
                            //   y: event.clientY + 10
                            // });

                            // Get the root category node's element
                            const rootCategoryNodeElement = document.getElementById("root-category-node"); // Assuming you have set an ID for the root category node

                            // Get the right-clicked category node's element
                            const categoryNodeElement = event.currentTarget;

                            // Get the positions of the two elements relative to the viewport
                            const rootCategoryRect = rootCategoryNodeElement.getBoundingClientRect();
                          
                            const categoryNodeRect = categoryNodeElement.getBoundingClientRect();


                            // Calculate the position difference between the two elements
                            const offsetX = categoryNodeRect.left - rootCategoryRect.left;
                            // const offsetY = categoryNodeRect.bottom - rootCategoryRect.top + 10;

                            // Because the position of the context menu is relative to its parent container: the category tree.
                            // But getBoundingClientRect() funtion only returns coordinates relative to the current viewport,that's to
                            // say the current browser window.
                            //
                            // After getting the current position of the right-clicked node's top edge via categoryNodeRect.top
                            // that value only defines the position of the current top edge of the node to the browser's top edge,
                            // in order to make the context menu appear at this position,we need to convert that value to the one that's
                            // realtive to the root node,the category tree.
                            // 
                            // That's why we need to const offsetY = categoryNodeRect.top - rootCategoryRect.top + 20;
                            //
                            // The value of window.innerHeight is the height of the browser,and when your browser is in full screen,this
                            // value is the height of your monitor,when it's windowed,the height is the browser's height.
                            let offsetY;
                            if(isMobile) {
                              offsetY = window.innerHeight - categoryNodeRect.top < 110 ? (categoryNodeRect.top - 94) : (categoryNodeRect.top + 24);
                            }else {
                              offsetY = window.innerHeight - categoryNodeRect.top < 110 ? (categoryNodeRect.top - rootCategoryRect.top + 108) : (categoryNodeRect.top - rootCategoryRect.top + 224);
                            }
                            
                            // window.alert("Root : top " + rootCategoryRect.top + ",bottom " + rootCategoryRect.bottom + "Node : top " + categoryNodeRect.top + ",bottom " + categoryNodeRect.bottom + " ViewPort Height : " + window.innerHeight + "offsetY : " + offsetY);
                          
                            
                            setPostContextMenuPosition({
                              x: Math.max(Math.min(event.clientX + 10, xIndicator - 170),60),
                              y: offsetY,
                            });



                            // Finally show the context menu,but right before that, we need to close up the possible 
                            // opened contex menu for categories
                            setShowContextMenu(false);
                            setShowPostContextMenu(true);
                          }}
                          // onMouseOver={(e) => {e.target.style.backgroundColor = "#ecf1f4"}}
                          // onMouseOut={(e) => {e.target.style.backgroundColor = "#f4f8fb"}}
                          >
                            <div
                              onMouseOver={(e) => {e.currentTarget.style.backgroundColor = "#ecf1f4"}}
                              onMouseOut={(e) => {e.currentTarget.style.backgroundColor = "#f4f8fb"}}
                            >
                              {post.postTitle}
                            </div>
                            {
                              openRenamePostTitle[post.postId]
                                ? <div
                                 style={{
                                  marginLeft:"5px",
                                  marginTop:"5px",
                                  width:xIndicator * 0.70,
                                  display: xIndicator > 566 ? "flex" : "",
                                  border:"solid red 1px"
                                }}
                                 >
                                    <TextField
                                    autoFocus
                                    style={{marginTop:"5px",width:xIndicator * 0.6}}
                                    size="small"
                                    id="outlined-textarea"
                                    label="Type new name"
                                    placeholder={post.postTitle}
                                    variant="outlined"
                                    //  style={{marginTop:"5px",width:xIndicator * 0.6}}
                                    value={newNameForPost}
                                    onChange={(e) => {
                                      setNewNameForPost(e.target.value);
                                    }}
                                    />
                                    <div style={{width:"160px",display:"flex"}}>
                                      <Button
                                        style={{margin:"10px 5px",fontSize:"10px"}}
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          // clear the contens and close
                                          setNewNameForPost('');
                                          setOpenRenamePostTitle((prevState) => ({ ...prevState, [post.postId] : false }));
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        style={{margin:"10px 5px",fontSize:"10px"}}
                                        size="small"
                                        variant="contained"
                                        disabled={newNameForPost.trim() === ''}
                                        onClick={() => {
                                          handleRenamePostOnRightClick();
                                          // finally clear contents and close the text field
                                          setNewNameForPost('');
                                          setOpenRenamePostTitle((prevState) => ({ ...prevState, [post.postId] : false }));
                                        }}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </div>
                                : <></>
                            }
                          </div>)
                        : null
                    }
                    
                       <Dialog
                          open={openDeletePostDialog}
                          onClose={() => {setOpenDeletePostDialog(false);}}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            Delete Post
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Are you sure to delete this post?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => {
                              // clear the stored right clicked id
                              setCurrentIdOfRightClickedPost('');
                              // close the dialog
                              setOpenDeletePostDialog(false);
                            }}>Cancel</Button>
                            <Button
                              onClick={() => {
                                handleDeletingPostFromCategoryTree();
                              }}
                              autoFocus>
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
                    
                    
                      <div
                        style={{
                          display: showPostContextMenu ? "block" : "none",
                          position: 'absolute',
                          left: postContextMenuPosition.x,
                          top: postContextMenuPosition.y,
                          zIndex: 99999,
                          backgroundColor: '#e8edf1',
                          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                          borderRadius: '4px',
                          padding: '8px',
                          width: '151px',
                          borderRadius: "10px"
                        }}
                      >
                        {/* Add your context menu options as buttons */}
                        <Button
                        onClick={() => {
                          // close the context menu
                          setShowPostContextMenu(false);
                          // Fist clear and close the possibly opened one
                          setOpenRenamePostTitle({});
                          setNewNameForPost('');
                          // open up the text field for user to type in the new name
                          setOpenRenamePostTitle((prevState) => ({ ...prevState, [currentIdOfRightClickedPost] : true }));
                        }}
                        style={{borderRadius:"10px",margin:"3px 0px"}}
                        sx={{
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: 8,
                            bottom: 0,
                            width: '86%',
                            borderBottom: '3px solid #919eab',
                            borderRadius: '20px 20px 20px 20px', // Only the top corners are not rounded
                          }, 
                        }}
                        startIcon={<DriveFileRenameOutlineIcon />}
                        >
                         {t('Rename')}
                        </Button>
                        <Button
                        onClick={() => {
                          // Pop up the dialog for confirmation
                          setOpenDeletePostDialog(true);
                          // close the context menu
                          setShowPostContextMenu(false);
                        }}
                        style={{borderRadius:"10px",margin:"3px 0px"}}
                        sx={{
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: 8,
                            bottom: 0,
                            width: '86%',
                            borderBottom: '3px solid #919eab',
                            borderRadius: '10px 10px 10px 10px', // Only the top  corners are not rounded
                          },
                        }}
                        startIcon={<DeleteSweepIcon />}
                        >
                        {t('Delete')}
                        </Button>
                      </div>
                    
                    
                </TreeItem>
                
              </div>
          )
  };

  const renderTreeChinese = (nodes) => {
    return (
              <TreeItem 
                key={nodes.id} 
                nodeId={nodes.id} 
                label={t(nodes.name) + '    ('+ nodes.subtotalcn + ')'}
                onClick={() => handleCategoryClick(nodes.id, nodes.name)}
                style={{
                  // border:"1px solid red",backgroundColor:"#edeff1",
                  margin:"5px",
                  color:"#798793",
                  borderRadius:"10px",
                }}>
                {Array.isArray(nodes.children)
                  ? nodes.children.map((node) => renderTreeChinese(node))
                  : null}
              </TreeItem>
          )
  };

  const renderTreeFrench = (nodes) => {
    return (
              <TreeItem 
                key={nodes.id} 
                nodeId={nodes.id} 
                label={t(nodes.name) + '    ('+ nodes.subtotalfr + ')'}
                onClick={() => handleCategoryClick(nodes.id, nodes.name)}
                style={{
                  // border:"1px solid red",backgroundColor:"#edeff1",
                  margin:"5px",
                  color:"#798793",
                  borderRadius:"10px",
                }}>
                {Array.isArray(nodes.children)
                  ? nodes.children.map((node) => renderTreeFrench(node))
                  : null}
              </TreeItem>
          )
  };

  const renderTreeJapanese = (nodes) => {
    return (
              <TreeItem 
                key={nodes.id} 
                nodeId={nodes.id} 
                label={t(nodes.name) + '    ('+ nodes.subtotaljp + ')'}
                onClick={() => handleCategoryClick(nodes.id, nodes.name)}
                style={{
                  // border:"1px solid red",backgroundColor:"#edeff1",
                  margin:"5px",
                  color:"#798793",
                  borderRadius:"10px",
                }}>
                {Array.isArray(nodes.children)
                  ? nodes.children.map((node) => renderTreeJapanese(node))
                  : null}
              </TreeItem>
          )
  };


  return (
    <div>
      
             <div style={{marginBottom:"70px"}}>
                <div style={{display:localStorage.getItem("userLoggingInfo") ? "block" : "none"}}>
                  <Tooltip
                    title={t('NewFolder')}
                    onClick={() => {
                      // close up the possibly opened `add new root post` textfield
                      setOpenNewPostInRootCategory(false);

                      setOpenNewRootCategory((prevState) => !prevState);
                      setNewRootCategoryContent('');
                      }}>
                    <IconButton>
                      <CreateNewFolderIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                   title={t('NewPost')}
                   onClick={() => {
                    // close up the possibly opened `add new root category` textfield
                    setOpenNewRootCategory(false);

                    setOpenNewPostInRootCategory((prevState) => !prevState);
                    setNewPostTitleInRootCategory('');
                   }}>
                    <IconButton>
                      <NoteAddIcon />
                    </IconButton>
                  </Tooltip>

                   {
                    !isMobile
                      ?  <Tooltip id="change-sort-order-button" title={t('ChangeSortOrder')} onClick={() => {
                          // get the button
                          const changeSortOrderElement = document.getElementById("change-sort-order-button");
      
                          // get the position
                          const sortOrderRect = changeSortOrderElement.getBoundingClientRect();
                          // window.alert("left: " +sortOrderRect.left + "right: " + sortOrderRect.right + "top : " + sortOrderRect.top + "bottom :" +sortOrderRect.bottom);
      
                          const offsetX = sortOrderRect.left
                          let offsetY = sortOrderRect.top;
      
                          // if(isMobile) {
                          //   offsetY = window.innerHeight - categoryNodeRect.top < 210 ? (categoryNodeRect.top - 185) : (categoryNodeRect.top + 24);
                          // }else {
                          //   offsetY = window.innerHeight - categoryNodeRect.top < 210 ? (categoryNodeRect.top - rootCategoryRect.top + 24) : (categoryNodeRect.top - rootCategoryRect.top + 224);
                          // }
      
                          setContextMenuPosition({
                            x: offsetX,
                            y: offsetY + 40,
                          });
      
                          // setShowChangeSortOrderContextMenu((prevState) => ({ ...prevState, showChangeSortOrderContextMenu : !prevState }));
                          if(showChangeSortOrderContextMenu) {
                            setShowChangeSortOrderContextMenu(false);
                          } else {
                            setShowChangeSortOrderContextMenu(true);
                          }
                          }}
                          >
                          <IconButton>
                            <SortIcon />
                          </IconButton>
                        </Tooltip>
                      :  <IconButton id="change-sort-order-button-mobile"  onClick={() => {
                        // get the button
                        const changeSortOrderElement = document.getElementById("change-sort-order-button-mobile");
    
                        // get the position
                        const sortOrderRect = changeSortOrderElement.getBoundingClientRect();
                        // window.alert("left: " +sortOrderRect.left + "right: " + sortOrderRect.right + "top : " + sortOrderRect.top + "bottom :" +sortOrderRect.bottom);
    
                        const offsetX = sortOrderRect.left
                        let offsetY = sortOrderRect.top;
    
                        // if(isMobile) {
                        //   offsetY = window.innerHeight - categoryNodeRect.top < 210 ? (categoryNodeRect.top - 185) : (categoryNodeRect.top + 24);
                        // }else {
                        //   offsetY = window.innerHeight - categoryNodeRect.top < 210 ? (categoryNodeRect.top - rootCategoryRect.top + 24) : (categoryNodeRect.top - rootCategoryRect.top + 224);
                        // }
    
                        setContextMenuPosition({
                          x: offsetX + 10,
                          y: offsetY + 38,
                        });
    
                        // setShowChangeSortOrderContextMenu((prevState) => ({ ...prevState, showChangeSortOrderContextMenu : !prevState }));
                        if(showChangeSortOrderContextMenu) {
                          setShowChangeSortOrderContextMenu(false);
                        } else {
                          setShowChangeSortOrderContextMenu(true);
                        }
                        }}>
                          <SortIcon />
                        </IconButton>

                   }
                 

                  {
                    showExpandAllIcon
                      ? <Tooltip title={t('ExpandAll')} onClick={() => {
                        // Add all children's ids to the expandedIdArray to expand all of them
                        handleExpandingAllNodes();
                        // Switch icon
                        setShowExpandAllIcon(false);
                      }}>
                          <IconButton>
                            <UnfoldMoreIcon />
                          </IconButton>
                        </Tooltip>
                      : <Tooltip title={t('CollapseAll')} onClick={() => {
                        setShowExpandAllIcon(true);
                        // Set the expandedIdArray to empty in order to collpase all ndoes
                        setExpandedIdArray([]);
                        }}>
                          <IconButton>
                            <UnfoldLessIcon />
                          </IconButton>
                        </Tooltip>
                  }
 
                </div>

                <div
                  style={{
                    display: showChangeSortOrderContextMenu ? "block" : "none",
                    position: 'absolute',
                    left: contextMenuPosition.x,
                    top: contextMenuPosition.y,
                    zIndex: 99999,
                    backgroundColor: '#e8edf1',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px',
                    padding: '8px',
                    width: '181px',
                    borderRadius: "10px"
                  }}
                >
                  {/* Add your context menu options as buttons */}
                  <Button
                    onClick={() => {
                      const sortedCategories = {
                        ...categories,
                        postShortDesc: Array.isArray(categories.postShortDesc)
                          ? [...categories.postShortDesc].sort((a, b) =>
                              a.postTitle.localeCompare(b.postTitle)
                            )
                          : [],
                        children: Array.isArray(categories.children)
                          ? categories.children.map((child) => ({
                              ...child,
                              postShortDesc: Array.isArray(child.postShortDesc)
                                ? [...child.postShortDesc].sort((a, b) =>
                                    a.postTitle.localeCompare(b.postTitle)
                                  )
                                : [],
                            }))
                          : [],
                      };
                      setCategories(sortedCategories);
                      setShowChangeSortOrderContextMenu(false);
                    }}
                    style={{borderRadius:"10px",margin:"3px 0px"}}
                    sx={{
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 8,
                      bottom: 0,
                      width:  '86%',
                      borderBottom: '3px solid #919eab',
                      borderRadius: '20px 20px 20px 20px', // Only the top corners are not rounded
                    },
                    }}
                    startIcon={<SortByAlphaIcon />}
                  >
                    {t('FileNameAZ')}
                  </Button>
                  <Button
                    onClick={() => {
                      const sortedCategories = {
                        ...categories,
                        postShortDesc: Array.isArray(categories.postShortDesc)
                          ? [...categories.postShortDesc].sort((a, b) =>
                              b.postTitle.localeCompare(a.postTitle)
                            )
                          : [],
                        children: Array.isArray(categories.children)
                          ? categories.children.map((child) => ({
                              ...child,
                              postShortDesc: Array.isArray(child.postShortDesc)
                                ? [...child.postShortDesc].sort((a, b) =>
                                    b.postTitle.localeCompare(a.postTitle)
                                  )
                                : [],
                            }))
                          : [],
                      };
                      setCategories(sortedCategories);
                      setShowChangeSortOrderContextMenu(false);
                    }}
                    style={{borderRadius:"10px",margin:"3px 0px"}}
                    sx={{
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 8,
                      bottom: 0,
                      width: '86%',
                      borderBottom: '3px solid #919eab',
                      borderRadius: '20px 20px 20px 20px', // Only the top corners are not rounded
                    }, 
                    }}
                    startIcon={<SortByAlphaIcon />}
                    >
                    {t('FileNameZA')}
                  </Button>
                  <Button
                    onClick={() => {
                      const sortedCategories = {
                        ...categories,
                        postShortDesc: Array.isArray(categories.postShortDesc)
                          ? [...categories.postShortDesc].sort((a, b) =>
                              new Date(b.postCreationDate) - new Date(a.postCreationDate)
                            )
                          : [],
                        children: Array.isArray(categories.children)
                          ? categories.children.map((child) => ({
                              ...child,
                              postShortDesc: Array.isArray(child.postShortDesc)
                                ? [...child.postShortDesc].sort((a, b) =>
                                    new Date(b.postCreationDate) - new Date(a.postCreationDate)
                                  )
                                : [],
                            }))
                          : [],
                      };
                      setCategories(sortedCategories);
                      setShowChangeSortOrderContextMenu(false);
                    }}
                    style={{borderRadius:"10px",margin:"3px 0px"}}
                    sx={{
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 8,
                      bottom: 0,
                      width: '86%',
                      borderBottom: '3px solid #919eab',
                      borderRadius: '20px 20px 20px 20px', // Only the top corners are not rounded
                    }, 
                    }}
                    startIcon={<WbSunnyIcon />}
                  >
                    {t('TimeNewOld')}
                  </Button>
                  <Button
                    onClick={() => {
                      const sortedCategories = {
                        ...categories,
                        postShortDesc: Array.isArray(categories.postShortDesc)
                          ? [...categories.postShortDesc].sort((a, b) =>
                              new Date(a.postCreationDate) - new Date(b.postCreationDate)
                            )
                          : [],
                        children: Array.isArray(categories.children)
                          ? categories.children.map((child) => ({
                              ...child,
                              postShortDesc: Array.isArray(child.postShortDesc)
                                ? [...child.postShortDesc].sort((a, b) =>
                                    new Date(a.postCreationDate) - new Date(b.postCreationDate)
                                  )
                                : [],
                            }))
                          : [],
                      };
                      setCategories(sortedCategories);
                      setShowChangeSortOrderContextMenu(false);
                    }}
                    style={{borderRadius:"10px",margin:"3px 0px"}}
                    sx={{
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 8,
                      bottom: 0,
                      width: '86%',
                      borderBottom: '3px solid #919eab',
                      borderRadius: '10px 10px 10px 10px', // Only the top  corners are not rounded
                    },
                    }}
                    startIcon={<NightsStayIcon />}
                    >
                   {t('TimeOldNew')}
                  </Button>
                </div>

                {//If the returned data from backend server is an empty hashmap,we don't render this <TreeView> at all.
                  !isCategoryEmpty
                    ? <TreeView
                        aria-label="rich object"
                        expanded={expandedIdArray}
                        // defaultExpanded={rootIdArray} // expand all nodes in the tree view
                        // must manually update the value every time you update the category.
                        sx={{flexGrow: 1, minWidth: 300, overflowY: 'auto'}} 
                        
                      >
                        {renderTree(categories)}
                      </TreeView>
                    : <></>
                }
                

                {
                  openNewRootCategory
                    ? <div style={{marginLeft:"5px",marginTop:"5px",width:xIndicator * 0.85,display: xIndicator > 566 ? "flex" : ""}}>
                        <TextField
                        autoFocus
                        style={{marginTop:"5px",width:xIndicator * 0.6}}
                        size="small"
                        id="outlined-textarea"
                        label={t('AddNewCategory')}
                        placeholder=""
                        variant="outlined"
                        //  style={{marginTop:"5px",width:xIndicator * 0.6}}
                         value={newRootCategoryContent}
                         onChange={(e) => {setNewRootCategoryContent(e.target.value);}}
                        />
                        <div style={{width:"160px",display:"flex"}}>
                          <Button
                            style={{margin:"10px 5px",fontSize:"10px"}}
                            size="small"
                            variant="contained"
                            onClick={() => {setOpenNewRootCategory(false);}}
                          >
                            {t('cancel')}
                          </Button>
                          <Button
                            style={{margin:"10px 5px",fontSize:"10px"}}
                            size="small"
                            variant="contained"
                            disabled={newRootCategoryContent.trim() === ''}
                            onClick={handleAddingNewCategory}
                          >
                            {t('confirm')}
                          </Button>
                        </div>
                      </div>
                    : <></>
                }

                {
                  openNewPostInRootCategory
                    ? <div style={{marginLeft:"5px",marginTop:"5px",width:xIndicator * 0.85,display: xIndicator > 566 ? "flex" : ""}}>
                        <TextField
                        autoFocus
                        style={{marginTop:"5px",width:xIndicator * 0.6}}
                        size="small"
                        id="outlined-textarea"
                        label={t('AddNewPost')}
                        placeholder=""
                        variant="outlined"
                        //  style={{marginTop:"5px",width:xIndicator * 0.6}}
                        value={newPostTitleInRootCategory}
                        onChange={(e) => {setNewPostTitleInRootCategory(e.target.value);}}
                        />
                        <div style={{width:"160px",display:"flex"}}>
                          <Button
                            style={{margin:"10px 5px",fontSize:"10px"}}
                            size="small"
                            variant="contained"
                            onClick={() => {setOpenNewPostInRootCategory(false);}}
                          >
                            {t('cancel')}
                          </Button>
                          <Button
                            style={{margin:"10px 5px",fontSize:"10px"}}
                            size="small"
                            variant="contained"
                            disabled={newPostTitleInRootCategory.trim() === ''}
                            onClick={handleAddingNewPostInRootCategory}
                          >
                            {t('confirm')}
                          </Button>
                        </div>
                      </div>
                    : <></>
                }
              </div>
    </div>
  );
}








// import PropTypes from 'prop-types';
// import { NavLink as RouterLink } from 'react-router-dom';
// // @mui
// import { Box, List, ListItemText } from '@mui/material';
// //
// import { StyledNavItem, StyledNavItemIcon } from './styles';

// // ----------------------------------------------------------------------

// NavSection.propTypes = {
//   data: PropTypes.array,
// };

// export default function NavSection({ data = [], ...other }) {
//   return (
//     <Box {...other}>
//       <List disablePadding sx={{ p: 1 }}>
//         {/* If the admin account has logged in,that means localStorage.getItem("userLoggingInfo") is not null
//         ,then we show all the menu options on the left panel.
        
//         If not,then localStorage.getItem("userLoggingInfo") will be null,
//         but the 'dashboard' menu won't be shown.*/}
//         {
//           localStorage.getItem("userLoggingInfo")
//           && data.map((item) => (
//                 <NavItem key={item.title} item={item} />
//               ))
//           || data.filter( (item) => item.title !== 'dashboard' ).map(
//                 (item) => (<NavItem key={item.title} item={item} />)
//               )
//         }
//       </List>
//     </Box>
//   );
// }

// // ----------------------------------------------------------------------

// NavItem.propTypes = {
//   item: PropTypes.object,
// };

// function NavItem({ item }) {
//   const { title, path, icon, info } = item;

//   return (
//     <StyledNavItem
//       component={RouterLink}
//       to={path}
//       sx={{
//         '&.active': {
//           color: 'text.primary',
//           bgcolor: 'action.selected',
//           fontWeight: 'fontWeightBold',
//         },
//       }}
//     >
//       <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

//       <ListItemText disableTypography primary={title} />

//       {info && info}
//     </StyledNavItem>
//   );
// }
