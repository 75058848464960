import PropTypes from 'prop-types';
// @mui
import { MenuItem, TextField } from '@mui/material';

// ----------------------------------------------------------------------

BlogPostsSort.propTypes = {
  options: PropTypes.array,
  onSort: PropTypes.func,
};

export default function BlogPostsSort({ options, sortByDate, sortingOrder }) {
  return (
    // sortingOrder has a default value "latest",because in the backend, we sort the data by date in descending order.
    <TextField select size="small" value={sortingOrder} >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} onClick={() => sortByDate(option.value)}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
