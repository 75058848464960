import PropTypes from 'prop-types';
// @mui
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent } from '@mui/material';
// utils
import { fDate } from '../../../utils/formatTime';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});



const StyledTitle = styled(Link)({

    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    fontWeight: 'bold',
    fontSize: '25px',
    
  });



const StyledAvatar = styled(Avatar)(({ theme }) => ({
  zIndex: 1,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const StyledInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginBottom: '-32px',
  marginRight: '35px',
  color: theme.palette.text.disabled,
}));

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

BlogPostCard2.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function BlogPostCard2({ post, index, onShowPostDetails}) {
  const navigate = useNavigate();
  const { id, title,coachName, coachImagePath, shortDescription, coverImagePath, minutesToRead,viewCount, creationDate, commentCount } = post; 

  const latestPost = index === 1 || index === 2;


  const showPostDetails = () => {
    onShowPostDetails(id);
  };

 
  return (
          <Grid item 
                xs={12} 
                sm={6} 
                md={((index+1)%5 === 1 || (index+1)%5 === 2) ? 6 : 4 }
                >
            <Card sx={{ pb:6,position: 'relative'}} 
                  // style={{height:"700px"}}
                  style={{cursor:"pointer"}}
                  onClick={showPostDetails}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transition = "transform 0.3s ease";
                    e.currentTarget.style.transform = "scale(1.04)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transition = "transform 0.9s ease";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                  >
              <StyledCardMedia>
                <StyledCover alt={title} src={coverImagePath} />
              </StyledCardMedia>

              <CardContent
                sx={{pt:1}}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
              <div style={{display:'flex'}}>
                  <div  style={{width:"150px",margin:"3px",height:"20px"}}>
                   
                    
                    <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
                      {fDate(creationDate)}
                    </Typography>
                  </div>

                  <div style={{width:"100%",height:"20px",margin:'3px'}}>
                    <StyledInfo>
                        <Box
                            // style={{border:"solid red 1px"}}
                            // key={index}
                            sx={{
                              mt: 0,
                              mr: -5,
                              display: 'flex',
                              alignItems: 'center',
                              // ml: index === 0 ? 0 : 1.5,
                              ...((true || latestPost) && {
                                color: 'grey.500',
                              }),
                            }}
                          >
                            <Iconify icon='eva:eye-fill' sx={{ width: 16, height: 16, mr: 0.5}} />
                            <Typography variant="caption">{viewCount}</Typography>
                            <Iconify icon='eva:message-square-fill' sx={{ width: 16, height: 16, mr: 0.5, ml: 1}} /> 
                            <Typography variant="caption">{commentCount}</Typography>
                        </Box>
                    </StyledInfo>
                  </div>
                </div>


                <StyledTitle
                  style={{marginTop:"10px"}}
                  color="inherit"
                  variant="subtitle2"
                  underline="none"
                  sx={{
                  pt:1
                  }}
                >
                  
                      {title}
                  
                      <p style={{fontSize:"15px",fontWeight:"normal"}}>
                      {shortDescription}
                      </p>
                  
                </StyledTitle>
              </CardContent>
              <StyledAvatar
                  alt={coachName}
                  src={coachImagePath}
                  sx={{bottom:16}}
                  />

              <StyledInfo>
                {coachName}
              </StyledInfo>
              
            </Card>
          </Grid>
    
  );
}
