import { useParams,useNavigate, useLocation } from 'react-router-dom';
import {useState , useEffect, useRef} from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Grid, Button, Container, Stack, Typography} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import './markdownCustomize.css';
import './postTemplate.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
// import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
// nord:bluish background,grey color,not taht bright
import {nord} from 'react-syntax-highlighter/dist/esm/styles/prism';
// a11yDark: brown,green,a little bright
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/prism';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';
import './treeItemTreeViewCustomize.css';
import ReplyIcon from '@mui/icons-material/Reply';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';


export default function ShowPostDetail(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const location = useLocation();
    // const { previousPosts, clickedCategory } = location.state || {};
    // useEffect(() => {
    //   // We can clear see the results here, that means we can successfully pass these two data
    //   // into the ShowPostDetail component, the problem is now we can't sent them back.
    //   window.alert(clickedCategory);
    //   window.alert(previousPosts);
    // }, []);

    const handleReturnBack = () => {

      navigate('/');
    };


    const { postId } = useParams();
//   const { id, title, content, shortDescription, coverImagePath, minutesToRead, creationDate } = post; 
    const [article,setArticle] = useState([]);
    const [articleComments,setArticleComments] = useState({});
    const [sureToDeleteThisPost, setSureToDeleteThisPost] = useState(false);
    const [showTextField, setShowTextField] = useState({});
    const [comment, setComment] = useState({});
    const [openCheckoutDialog, setOpenCheckoutDialog] = useState(false);

    // To close the dialog, we need to do the  following actions
    const handleCloseCheckoutDialog = () => {
      // Clear state variables
      setPromotionCode("");
      setUserRealName("");
      setUserEmailAddress("");
      setCheckCheckbox(false);
      
      
      // Close the dialog
      setOpenCheckoutDialog(false);
    };


    const [promotionCode, setPromotionCode] = useState("");
    const [userRealName, setUserRealName] = useState("");
    const [userEmailAddress, setUserEmailAddress] = useState("");
    const [checkCheckbox, setCheckCheckbox] = useState(false);
    const [openDeleteAllCommentsDialog,setOpenDeleteAllCommentsDialog] = useState(false);
    const [openDeleteChildCommentDialog,setOpenDeleteChildCommentDialog] = useState(false);
    // const [openDeleteAllCommentsDialogMobile,setOpenDeleteAllCommentsDialogMobile] = useState(false);
    const [openDeleteChildCommentDialogNewComponentMobile, setOpenDeleteChildCommentDialogNewComponentMobile] = useState(false);
    // As for mobile devices,in the root comment tree,we should store each comment's openDeleteChildCommentDialogRootCommentTreeMobile separately and combine it with clicked comment's id, just as the <TextField>.
    // Otherwise,the id will always be the root's id,causing deleting comments failed with null pointer exception.
    const [openDeleteChildCommentDialogRootCommentTreeMobile, setOpenDeleteChildCommentDialogRootCommentTreeMobile] = useState({});

    // comments inside the comment section are all children comments,they are comments of those child comments of
    // the root comment.
    // As for the outer one,is only for the root comment
    const [rootComment, setRootComment] = useState('');
    const handleClearingRootComment = () => {
      setRootComment((prevContent) => {
        // Return an empty string to clear the content
        return '';
      });
    };

    const StyledAvatar = styled(Avatar)(({ theme }) => ({
      // zIndex: 1,
      width: isMobile ? 32 : 50,
      height: isMobile ? 32 : 50,
      position: 'relative',
      top: theme.spacing(0.7),
      left: theme.spacing(0),
      bottom: theme.spacing(-2),
    }));

    const handlePostingRootComments = () => {
      let language = "en";
      const payload = {
        "language": language,
        "postId" : postId,
        "content" : rootComment
      };

      const requestOptions = {
        method: "POST",
        headers: {"Content-Type" : "application/json"},
        body: JSON.stringify(payload),
      };

       // save the post's comments
       fetch(`${process.env.REACT_APP_API_URL}/comment/save/root/comment`, requestOptions )
       .then(response => response.text())
       .then(data => {
         window.alert(data);
         // After posting the root comment,we need to clear the textfield
         setRootComment('');

         // Once posted comment,fetch comment data immediately to make those changes show immediately
         fetch(`${process.env.REACT_APP_API_URL}/comment/get/by/` + postId )
          .then(response => response.json())
          .then(data => {
              setArticleComments(data);
          })
          .catch(error => console.error);
       })
       .catch(error => console.error);

       

    };

    const handlePostingChildComments = (nodeId) => {
      let language = "en";

      const payload = {
        "language" : language,
        "parentId" : nodeId,
        "content" : comment[nodeId]
      };

      const requestOptions = {
        method: "POST",
        headers: {"Content-Type" : "application/json"},
        body: JSON.stringify(payload)
      };

      // window.alert("The id of the clicked child node is : " + nodeId);
      fetch(`${process.env.REACT_APP_API_URL}/comment/save/child/comment`, requestOptions )
        .then(response => response.text())
        .then(data => {
          window.alert(data);
          // After posting the child comment, we need to clear the child comment textfield
          setComment((prevState) => ({ ...prevState, [nodeId]: '' }));

           // Once posted comment,fetch comment data immediately to make those changes show immediately
          fetch(`${process.env.REACT_APP_API_URL}/comment/get/by/` + postId )
          .then(response => response.json())
          .then(data => {
              setArticleComments(data);
               // window.alert("The nodeid of the reply div being clicked is : " + nodeId);
              setShowTextField((prevState) => ({ ...prevState, [nodeId]: !prevState[nodeId] }));
              // Each time we click to reply, reset the comments inside it.
              setComment((prevState) => ({ ...prevState, [nodeId]: '' }));
          })
          .catch(error => console.error);
        })
        .catch(error => console.error)
    }


    // const handleRootCommentChange = () => {

    // };
    // window.alert(postId);

    // The following test data is for testing the comment thing:
    const data = {
      id: 'root',
      name: 'Comments',
      subtotal: '1329',
      children: [
        {
          id: '11',
          name: "Actually this one is the best I've ever seen.",
          subtotal: '0',
          children: []
        },
        {
          id: '13',
          name: 'Good content,I appreciate your hardword,it really inspires me a lot.',
          subtotal: '123',
          children: [
          
            {
              id: '132',
              name: 'Totally agree with you.',
              subtotal: '20',
              children : [
                {
                  id: '1321',
                  name: 'Totally agree with you.',
                  subtotal: '20',
                },
                {
                  id: '1322',
                  name: 'Totally agree witee In order to eliminate the misjudgment caused by insufficient image data, the system will discard the final result with the number of matches less than 10, and prompt that the face recognition has not effectively identified the target. If the number of matches is greater than or equal to 10, the data is sent to the SpringBoot backend server for further processing.',
                  subtotal: '20',
                }
              ]
            },
            {
              id: '133',
              name: "Can't agree anymore,good luck.",
              subtotal: '30'
            }
          ]
        },
        {
          id: '14',
          name: 'Really love the page layout design,keep in touch.',
          subtotal: '0'
        }
      ],
    };

    // This one stores the id of the roor, so that we can expand the root by default
    let rootIdArray = [];
    rootIdArray.push(articleComments.id);
    const [selectedComment, setSelectedComment] = useState(null);
    const [toggleRootTreeVisibility, setToggleRootTreeVisibility] = useState(true);

    const [expandedNodes, setExpandedNodes] = useState([]);

    // This one stores the category ids that needs to be expanded,when the user clicks a child comment inside the 
    // root,we display all its children and grandchildren.When the user clicks those children,we don't expand anything.
    // So that we can always have enough space to display comments regardless of how deep the comment tree is.
    const [idToExpandArray, setIdToExpandArray] = useState([]);
    // When click on the root, we only display its direct children, but when clicking any one of those children,we
    // display all its children and grandchildren.So we need to set up a list of arrays to store those children.And
    // for each node separately.
    const [allChildrenArray, setAllChildrenArray] = useState({});

  // Event handler for node toggle
  const handleNodeToggle = (event, nodeIds) => {
    // Only expand the first selected node and collapse others
    // const selectedNode = nodeIds[0];
    const selectedNode = data.id;
    setExpandedNodes([selectedNode]);
    };

    const [openChildCommentsOnMobile,setOpenChildCommentsOnMobile] = useState(false);

    const renderSelectedCommentMobile = (comments) => {
      

      // Render the selected comments and its children recursively in a separate container
      // You can customize the container's style and layout as needed
      return (
        comments.content.startsWith("Comments")
          ? <div style={{color:"#798793"}}>
              <div>
                {Array.isArray(comments.children) && comments.children.map((child) => renderSelectedCommentMobile(child))}
              </div>
            </div>
          :  <div>
                <div style={{color:"#798793",border:"solid 1px red",display:"flex"}}>
                  {/* <div style={{borderRadius:"30px",position:"relative",top:6,left:0,border:"solid 1px red",width:"32px",height:"32px"}}></div> */}
                  <StyledAvatar
                    alt={comments.authorName}
                    src={comments.profileImagePath}
                    sx={{bottom:16}}
                    />
                
                  <div style={{border:"solid green 1px",borderRadius:"10px",width:"80vw"}}>
                    <div style={{display:"flex"}}>
                      <div style={{border:"solid 1px brown",marginLeft:"10px",height:"29px",fontSize:"11px",color:"black",fontWeight:"bold",}}>{comments.authorName}</div>
                      <div style={{border:"solid 1px brown",marginLeft:"10px",height:"29px",fontSize:"11px",color:"#747474",fontWeight:"lighter",}}>{getTimeDifference(comments.creationDate)}</div>
                    </div>
                    <div style={{padding:"5px",borderRadius:"10px",backgroundColor:"pink",border:"solid 1px green",fontSize:'14px', lineHeight:"19px"}}>
                      {/* {comments.replyCount === 0 ? t(comments.content) : t(comments.content) + '    ('+ comments.replyCount + ' ' +  'replies)'} */}
                      {/* {t(comments.content)} */}
                      {
                        comments.parentAuthorName
                        ? <div>{t('ReplyTo')} <strong>{comments.parentAuthorName}</strong>:{t(comments.content)}</div>
                        : t(comments.content)
                      }
                      {/* label={
                            nodes.content === "Comments"
                              ? nodes.replyCount + ' ' + nodes.content
                              : nodes.replyCount === 0
                              ? nodes.parentAuthorName
                                ? <>{"Reply to "}<strong>{nodes.parentAuthorName}</strong>{" : "}{" " + t(nodes.content)}</>
                                : t(nodes.content)
                              : nodes.parentAuthorName
                                ? <>{"Reply to "}<strong>{nodes.parentAuthorName}</strong>{" : "}{" " + t(nodes.content) + '    (' + nodes.replyCount + ' ' + 'replies)'}</>
                                : <><strong>{t(nodes.content)}</strong>{"    (" + nodes.replyCount + ' ' + 'replies)'}</>
                          } */}
                    </div>
                    {
                      comments.content === "Comments" 
                      ? null
                      : <div style={{display:"flex"}}>
                          {/* <div 
                            style={{
                              borderRadius:"7px",
                              border:"solid 1px black",
                              width:"50px",
                              height:"24px",
                              fontSize:"13px",
                              fontWeight:"bold",
                              color:"darkred",
                              marginLeft:"14px",
                              cursor:"pointer"
                            }} 
                            onClick={() => handleReplyClick(comments.id)}
                            onMouseEnter={(e) => { e.target.style.color = "red";}} 
                            onMouseLeave={(e) => { e.target.style.color = "darkred" }}
                            >
                          <div style={{position:"relative",bottom:7,left:7.5}}>Reply</div>
                          </div> */}
                          <Button  
                            variant="outlined" 
                            size="small" 
                            style={{fontSize:"11px",paddingLeft:"1px",paddingRight:"1px"}}
                            onClick={() => handleReplyClick(comments.id)}
                            >
                            {t('Reply')}
                          </Button>
                          {
                            <Dialog
                              open={openDeleteChildCommentDialogNewComponentMobile}
                              onClose={() => {setOpenDeleteChildCommentDialogNewComponentMobile(false);}}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {t('DeleteAComment')}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                 {t('ConfirmDeleteComments')}
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={() => {
                                  handleDeletingChildComment(comments.id);
                                  setOpenDeleteChildCommentDialogNewComponentMobile(false);
                                  // After deleting the post,we need to go back to relfect changes
                                  setSelectedComment(null);
                                  setToggleRootTreeVisibility(true);
                                }}>{t('confirm')}</Button>
                                <Button onClick={() => {setOpenDeleteChildCommentDialogNewComponentMobile(false);}} autoFocus>
                                  
                                </Button>
                              </DialogActions>
                            </Dialog>
                          }

                          {
                            localStorage.getItem("userLoggingInfo")
                            ? <Button 
                                variant="outlined"
                                size="small"
                                style={{fontSize:"11px",paddingLeft:"1px",paddingRight:"1px"}}
                                onClick={() => {setOpenDeleteChildCommentDialogNewComponentMobile(true);}}>
                                {t('Delete')}
                              </Button>
                            : <></>
                          }
                        </div>
                    }

                    {
                      showTextField[comments.id] && comments.content !== "Comments" && (
                      <div>
                          <TextField
                            id="outlined-textarea"
                            label={t('LeaveComment')}
                            placeholder=""
                            multiline
                            variant="outlined"
                            maxRows={4}
                            style={ isMobile ? {width:"95%",margin:"10px"} : {width:"70%",margin:"10px"}}
                            onChange={(e) => handleCommentChange(comments.id, e.target.value)}
                            value={comment[comments.id]}
                          />
                          <Button 
                          variant="contained" 
                          style={isMobile ? {margin:"10px 5px",position:"relative",right:"-40vw"} : {margin:"20px 10px"}}
                          onClick={(prevState) => {
                            setComment((prevState) => ({ ...prevState, [comments.id]: '' }));
                          }}
                          >
                            {t('cancel')}
                          </Button>
                          <Button 
                            variant="contained" 
                            onClick={() => {
                              handlePostingChildComments(comments.id);
                              // This component is designed for mobile devices,when the user clicks on a comment
                              // all its children will be rendered inside this component,
                              // Once we reply comments to one of those children,we'd like to render this function again
                              // to reflect the changes right away
                              //
                              // The handlePostingChildComments() function will save the comment and fetch comment tree
                              // again to reflect changes immediately,but here we're in another component,those changes
                              // won't be reflected here,so we need to manually do it.
                              //
                              setSelectedComment(null);
                              setToggleRootTreeVisibility(true);
                            }}
                            // Disable if the comments is empty or contains only whitespaces
                            disabled={!comment[comments.id].trim()} 
                            style={isMobile ? {margin:"10px 5px",position:"relative",right:"-40vw"} : {margin:"20px 10px"}}
                            >
                            {t('Reply')}
                            </Button>
                        </div>
                      )
                    }
                          
                    <div>
                      {/* {
                        Array.isArray(comment.children) && comment.children.map((child) => 
                          (
                            <div style={{borderRadius:"10px",backgroundColor:"pink",border:"solid 1px green",fontSize:'14px', lineHeight:"19px"}}>
                              {t(child.name) + '    ('+ child.subtotal + ' ' +  'replies)'}
                            </div>
                          )
                        )
                      } */}
                    </div>
                    
                  </div>
                </div>
                <div>
                  {Array.isArray(comments.children) && comments.children.map((child) => renderSelectedCommentMobile(child))}
                </div>
            </div>

      );
    };

    

    // When the user clicks a comment on mobile devices,all its child comments will be shown
    // alongside with a `Back` button to return back.When that button was clicked ,the follwoing function will be 
    // triggered which
    // 1. sets the selectedComment to null in order to close the child comment section
    // 2. and also sets ToggleRootTreeVisibility to true in order to make the original root comments visible again.
    // Now we want it to do another thing,
    // 3. scroll the page back to where the user just left.
    const handleBackButtonClick = () => {
      

      setSelectedComment(null);
      setToggleRootTreeVisibility(true);
    };

    const getTimeDifference = (dateString) => {
      // Convert the date string to a JavaScript Date object
    const date = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - date;

    // Define time units in milliseconds
    const oneSecond = 1000;
    const oneMinute = oneSecond * 60;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const oneMonth = oneDay * 30;
    const oneYear = oneMonth * 12;

    // Calculate the time difference in different units
    const years = Math.floor(timeDifference / oneYear);
    const months = Math.floor(timeDifference / oneMonth);
    const days = Math.floor(timeDifference / oneDay);
    const hours = Math.floor(timeDifference / oneHour);
    const minutes = Math.floor(timeDifference / oneMinute);
    const seconds = Math.floor(timeDifference / oneSecond);
    
    // if(localStorage.getItem("currentLanguage") === "cn"){
    //   if (years > 0) {
    //     return `${years} 年前`;
    //   } else if (months > 0) {
    //     return `${months} 个月前`;
    //   } else if (days > 0) {
    //     return `${days} 天前`;
    //   } else if (hours > 0) {
    //     return `${hours} 小时前`;
    //   } else if (minutes > 0) {
    //     return `${minutes} 分钟前`;
    //   } else {
    //     return `${seconds} 秒前`;
    //   }
    // } else if(localStorage.getItem("currentLanguage") === "jp"){
    //   if (years > 0) {
    //     return `${years}年前`;
    //   } else if (months > 0) {
    //     return `${months}ヶ月前`;
    //   } else if (days > 0) {
    //     return `${days}日前`;
    //   } else if (hours > 0) {
    //     return `${hours}時間前`;
    //   } else if (minutes > 0) {
    //     return `${minutes}分前`;
    //   } else {
    //     return `${seconds}秒前`;
    //   }
    // } else if(localStorage.getItem("currentLanguage") === "fr"){
    //   if (years > 0) {
    //     return `Il y a ${years} an${years > 1 ? 's' : ''}`;
    //   } else if (months > 0) {
    //     return `Il y a ${months} moi${months > 1 ? 's' : ''}`;
    //   } else if (days > 0) {
    //     return `Il y a ${days} jour${days > 1 ? 's' : ''}`;
    //   } else if (hours > 0) {
    //     return `Il y a ${hours} heure${hours > 1 ? 's' : ''}`;
    //   } else if (minutes > 0) {
    //     return `Il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
    //   } else {
    //     return `Il y a ${seconds} seconde${seconds > 1 ? 's' : ''}`;
    //   }
    // } else {
      // Return the formatted time difference
      if (years > 0) {
        return `${years} ${years === 1 ? 'year' : 'years'} ago`;
      } else if (months > 0) {
        return `${months} ${months === 1 ? 'month' : 'months'} ago`;
      } else if (days > 0) {
        return `${days} ${days === 1 ? 'day' : 'days'} ago`;
      } else if (hours > 0) {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      } else if (minutes > 0) {
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
      } else {
        return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
      }
    // }


  }; 
  // When on mobile,if the user clicks on a comment to see its replies,that commentID will besaved here.
  // So that we can later use it to scroll to where the user just left.
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const scrollToRef = useRef();

  // Function to scroll to the selected comment's ref
  const scrollToSelectedComment = () => {
    if (isMobile && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleReplyClick = (nodeId) => {
    // window.alert("The nodeid of the reply div being clicked is : " + nodeId);
    setShowTextField((prevState) => ({ ...prevState, [nodeId]: !prevState[nodeId] }));
    // Each time we click to reply, reset the comments inside it.
    setComment((prevState) => ({ ...prevState, [nodeId]: '' }));
  };

  const handleCommentChange = (nodeId, value) => {
    setComment((prevState) => ({ ...prevState, [nodeId]: value }));
  };

  const handleDeletingChildComment = (nodeId) => {
    let language = "en"
    // window.alert("node to be deleted : " + nodeId);
    fetch(`${process.env.REACT_APP_API_URL}/comment/delete/child/comment?commentId=` + nodeId + "&language=" + language)
      .then(response => response.text())
      .then(data => {
        window.alert(data);
        // After successfully deleted the child comment,re-fetch comment data to reflect the change
        fetch(`${process.env.REACT_APP_API_URL}/comment/get/by/` + postId)
          .then(response => response.json()) 
          .then(data => {
            setArticleComments(data);
          })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));
  };
  

    const renderTree = (nodes) => {

      // const storedPosition = localStorage.getItem("scrollPosition");
      // if(isMobile && storedPosition !== null) {
      //   window.scrollTo(0, parseInt(storedPosition));
      //   // clear the stored position so that it won't interfere with other comments
      //   localStorage.removeItem("scrollPosition");
      // }
      
     

     

      const handleCommentClickMobile  = (nodes) => {
          // Mobile devices have a relatively small screen,so when the user checks replies for a certain comment
          // We don't render those comments in a tree format like what we usually do on computers.We direactly open up
          // a separate page to display all child comments including those grandchildren comments.
          //
          // And provide a `Back` button for the user to return back.
          //
          // Also we want to keep track of the scroll position so that when the user comes back,everything won't start from 
          // scratch,but just where the user left.
          //
          // Before rendering the child comments on mobile devices, let's store the current scroll position
          // const scrollPosition = window.scrollY;
          // localStorage.setItem("scrollPosition", scrollPosition);

          if(nodes.content !== "Comments"){
            setSelectedComment(nodes);
            setToggleRootTreeVisibility(false);
          }
          

        // if(nodes.id === clickedId) {
        //   setSelectedComment(nodes);
        //   setToggleRootTreeVisibility(false);
        //   return nodes;
        // }

        // if (Array.isArray(nodes.children)) {
        //   for (const child of nodes.children) {
        //     const foundElement = handleCommentClickMobile(child, clickedId);
        //     if (foundElement) {
        //       setSelectedComment(foundElement);
        //       setToggleRootTreeVisibility(false);
        //       return foundElement;
        //     }
        //   }
        // }
        
        // return null;
      };



      // This return statement is only for the function renderTree(),not for the component itself
      return (
                  <div style={{
                    display:"flex",
                    // border:"solid red 1px",
                  }}
                  >
                    {
                      nodes.content === "Comments" ? null
                      : <div>
                          {/* <div style={{borderRadius:"50px",position:"relative",top:4,left:2,border:"solid 1px red",width:"50px",height:"50px"}}></div> */}
                          <StyledAvatar
                          alt={nodes.authorName}
                          src={nodes.profileImagePath}
                          sx={{bottom:16}}
                          />
                        </div>
                    }
                    <div style={{
                      overflow:"hidden",
                      borderRadius:"8px",
                      width:"100%",
                      margin: isMobile ? "0px" : "5px"
                    }}
                    >
                      <div style={{display:"flex"}}>
                        {
                          nodes.content === "Comments" 
                          ? <div style={{backgroundColor:"#edeff1",height:"20px",width:"100%",fontSize:"8px",color:"black",fontWeight:"bold",}}></div>
                          : <div
                              style={{
                                marginLeft:"10px",
                                height:"29px",
                                fontSize:isMobile? "10px" : "11px",
                                color:"black",
                                fontWeight:"bold",
                              }}
                            >
                            {nodes.authorName}
                            </div>
                        }
                        {
                          nodes.content === "Comments" ? null
                          : <div
                              style={{
                                marginLeft:isMobile ? "5px" : "10px",
                                height:"29px",
                                fontSize:isMobile ? "9px" : "12px",
                                color:"#747474",
                                fontWeight:"lighter"
                              }}
                            >
                              {getTimeDifference(nodes.creationDate)}
                            </div>
                        }
                      </div>
                      
                      <div className="onlyForCommentsExcludeCategory">
                        <TreeItem 
                          key={nodes.id} 
                          nodeId={nodes.id} 
                          // label={nodes.content === "Comments" ? nodes.replyCount + ' ' + nodes.content : nodes.replyCount === 0 ?  ( nodes.parentAuthorName ? "Reply to (" + nodes.parentAuthorName + "):  " + t(nodes.content) : t(nodes.content)) : ( nodes.parentAuthorName ? ("Reply to (" + <strong>nodes.parentAuthorName</strong> + "):  " +  t(nodes.content) + '    ('+ nodes.replyCount + ' ' +  'replies)') : (t(nodes.content) + '    ('+ nodes.replyCount + ' ' +  'replies)'))}
                          label={
                            nodes.replyCount !== undefined 
                              ? nodes.id === articleComments.id
                                  ? nodes.replyCount + ' ' + t('Comments')
                                  : nodes.replyCount === 0
                                    ? nodes.parentAuthorName
                                      ? <><span style={isMobile ? {fontSize: "10px"} : {fontSize:"12px"}}>{t('ReplyTo')}<strong>{nodes.parentAuthorName}</strong>{":"}</span>{" " + t(nodes.content)}</>
                                      : t(nodes.content)
                                    : nodes.replyCount === 1
                                      ? nodes.parentAuthorName
                                          ? <><span style={isMobile ? {fontSize: "10px"} : {fontSize:"12px"}}>{t('ReplyTo')}<strong>{nodes.parentAuthorName}</strong>{" : "}</span>{" " + t(nodes.content) + '    (' + nodes.replyCount + ' ' + t('Reply') + ')'}</>
                                          : <>{t(nodes.content)}{"    (" + nodes.replyCount + ' ' + t('Reply') + ')'}</>
                                      : nodes.parentAuthorName
                                          ? <><span style={isMobile ? {fontSize: "10px"} : {fontSize:"12px"}}>{t('ReplyTo')}<strong>{nodes.parentAuthorName}</strong>{" : "}</span>{" " + t(nodes.content) + '    (' + nodes.replyCount + ' ' + t('Replies') + ')'}</>
                                          : <>{t(nodes.content)}{"    (" + nodes.replyCount + ' ' + t('Replies') + ')'}</>
                              : nodes.parentAuthorName
                                  ? <><span style={isMobile ? {fontSize: "10px"} : {fontSize:"12px"}}>{t('ReplyTo')}<strong>{nodes.parentAuthorName}</strong>{" : "}</span>{" " + t(nodes.content)}</>
                                  : t(nodes.content)
                          }
                          // onClick={() => handleCategoryClick(nodes.id)}
                          onClick={() => {
                            if(idToExpandArray.includes(nodes.id)){
                              setIdToExpandArray((prevArray) => prevArray.filter((id) => id !== nodes.id));
                            } else {
                              setIdToExpandArray((prevArray) => [...prevArray, nodes.id]);
                            }
                           
                           
                          }}
                          style={{
                            // border:"1px solid red",
                            backgroundColor:"#edeff1",
                            // backgroundColor:"darkgrey",
                            // margin:"10px",
                            color:"#798793",
                            // borderRadius: "20px",
                          }}>
                            {
                              <Dialog
                                open={openDeleteChildCommentDialog}
                                onClose={() => {setOpenDeleteChildCommentDialog(false);}}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {t('DeleteAComment')}
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    {t('ConfirmDeleteComments')}
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={() => {
                                    handleDeletingChildComment(nodes.id);
                                    setOpenDeleteChildCommentDialog(false);
                                    // window.alert("The comment id is : " + nodes.id);
                                    }}>
                                      {t('confirm')}
                                  </Button>
                                  <Button onClick={() => {setOpenDeleteChildCommentDialog(false);}} autoFocus>
                                  {t('cancel')}
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            }
                            
                            {
                              nodes.content === "Comments" 
                              ? null
                              : <div
                               style={
                                isMobile
                                  ? {height:"20px"}
                                  : {}
                                }
                              >
                                  <Button 
                                   size="small"
                                   style={
                                    isMobile
                                    ? {fontSize:"11px",paddingLeft:"1px",paddingRight:"1px",borderRadius:"8px",position:"relative",top:"-10px"}
                                    : {fontSize:"11px",paddingLeft:"1px",paddingRight:"1px"}
                                    
                                    }
                                   onClick={() => handleReplyClick(nodes.id)}
                                   >
                                    {t('Reply')}
                                  </Button>
                                  { localStorage.getItem('userLoggingInfo')
                                    ? <Button
                                        size="small"
                                        style={
                                          isMobile
                                          ? {fontSize:"11px",paddingLeft:"1px",paddingRight:"1px",borderRadius:"8px",position:"relative",top:"-10px"}
                                          : {fontSize:"11px",paddingLeft:"1px",paddingRight:"1px"}
                                          
                                          }
                                        // onClick={() => handleDeletingChildComment(nodes.id)}
                                        onClick={() => {setOpenDeleteChildCommentDialog(true);}}
                                        >
                                        {t('Delete')}
                                      </Button>
                                    : <></>
                                  }
                                </div>
                            }
                            {
                              showTextField[nodes.id] && nodes.content !== "Comments" && (
                              <div>
                                  <div>
                                    <TextField
                                      size={isMobile ? "small" : ""}
                                      id="outlined-textarea"
                                      label={t('LeaveComment')}
                                      placeholder=""
                                      multiline
                                      variant="outlined"
                                      maxRows={4}
                                      style={ isMobile ? {width:"100%"} : {width:"70%",margin:"10px"}}
                                      onChange={(e) => handleCommentChange(nodes.id, e.target.value)}
                                      value={comment[nodes.id]}
                                    />
                                  </div>
                                  <div>
                                    <Button 
                                    size={isMobile ? "small" : ""}
                                    variant="contained" 
                                    style={isMobile ? {margin:"0px 2px"} : {margin:"20px 10px"}}
                                    onClick={(prevState) => {
                                      setComment((prevState) => ({ ...prevState, [nodes.id]: '' }));
                                      setShowTextField((prevState) => ({ ...prevState, [nodes.id] : !prevState }));
                                    }}
                                    >
                                     {t('cancel')}
                                    </Button>
                                    <Button 
                                      size={isMobile ? "small" : ""}
                                      variant="contained" 
                                      // Disable if the comment is empty or contains only whitespaces
                                      onClick={() => handlePostingChildComments(nodes.id)}
                                      disabled={!comment[nodes.id].trim()} 
                                      style={isMobile ? {margin:"0px 5px"} : {margin:"20px 10px"}}
                                      >
                                     {t('Reply')}
                                      </Button>
                                  </div>
                                </div>
                              )
                            }
                          {/* the root comment can only display its direct children,any other commet nodes must display all children and grandchildren once being clicked. */}
                          { Array.isArray(nodes.children)
                              ? nodes.children.map((node) => renderTree(node))
                              : null
                          }

                          {/* {
                            nodes.id !== articleComments.id && Array.isArray(allChildrenArray[nodes.id])
                              ? allChildrenArray[nodes.id].map((child) => renderTree(child))
                              : null
                          } */}
                        </TreeItem>
                      </div>
                    </div>
                  </div>
            )
    };



 

    useEffect(() => {
        scrollToSelectedComment();
        setSelectedCommentId(null);
        const userAgent = window.navigator.userAgent;
        let platformDetail;
        let platform;
        if (/iPhone|iPad|iPod/i.test(userAgent)) {
          platformDetail = 'Apple Phone';
          platform = 'mobile';
        } else if (/Macintosh|Mac/i.test(userAgent)) {
          platformDetail = 'Apple Computer';
          platform = 'pc';
        } else if (/Android/i.test(userAgent)) {
          platformDetail = 'Android Phone';
          platform = 'mobile';
        } else if (/Windows/i.test(userAgent)) {
          platformDetail = 'Windows Computer';
          platform = 'pc';
        } else if (/Linux|Ubuntu|Manjaro|Nix|Fedora|Centos|Redhat|kodachi|FreeBSD/i.test(userAgent)) {
          platformDetail = 'Linux Computer';
          platform = 'pc';
        } else {
          platformDetail = 'Android Phone';
          platform = 'mobile';
        }
        // window.alert(window.navigator.userAgent + "###Device Type is : " + platform + " and is from : " + platformDetail);
        
        // Get the post
        fetch(`${process.env.REACT_APP_API_URL}/posts/` + postId + "?platform=" + platform + "&platformDetail=" + platformDetail)
            .then(response => {
              if(!response.ok){
                throw new Error("No such post exists!");
              }
              return response.json();
            })
            .then(data => {
              // Although we only passed one id to fetch the post,the returned post is in an ArrayList<Post> format,
              // since it's only one element, we need to fetch it using data[0]
                setArticle(data[0]);
            })
            .catch(error => console.error);

        // Get the post's comments
        fetch(`${process.env.REACT_APP_API_URL}/comment/get/by/` + postId )
            .then(response => response.json())
            .then(data => {
                setArticleComments(data);
                // window.alert(data);
            })
            .catch(error => console.error);

      
        localStorage.setItem("currentLanguage","en");
        i18n.changeLanguage("en");
        // if(localStorage.getItem("currentLanguage")){
        //     i18n.changeLanguage(localStorage.getItem("currentLanguage"));
        // } else {
        //     if(navigator.language.startsWith("zh")){
        //         localStorage.setItem("currentLanguage","cn");
        //         i18n.changeLanguage("cn");
        //     } else {
        //         localStorage.setItem("currentLanguage","en");
        //         i18n.changeLanguage("en");
        //     }
        // }

        // We also want to prevent the user from zooming in or out the page
        // Disable user scaling
        const disableUserScaling = () => {
            const viewportMetaTag = document.querySelector('meta[name="viewport"]');
            viewportMetaTag.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
        };
    
        disableUserScaling();
    } , [selectedCommentId]);

    // Extract the month and day from the creationDate
    // const originalCreationDate = article.creationDate;
    // 
    // Originally, the creationDate is represneted in seconds ,so we need to convert it back to the date time format
    // using the following statement,
    // const creationDate = new Date(originalCreationDate);
    //
    // But now, the creationDate is already in date time format, we don't need to convert anymore.

    const creationDate = new Date(article.creationDate);
    const year = creationDate.getFullYear();
    const month = creationDate.toLocaleString('default', { month: 'long' }).toUpperCase();
    const monthAsia = creationDate.getMonth() + 1;
    const day = creationDate.getDate();

    const handleJumpToEditPage = () => {
        // window.alert(article.id);
        navigate(`/editPage/${article.id}`);
    }

    const handleCancellingDelete = () => {
        setSureToDeleteThisPost(false);
    }

    const handleDeletePostConfirmDialog = () => {
        setSureToDeleteThisPost(true);
    }

    const handleDeletePost = () => {
        
        // window.alert(article.id);
        let currentLanguage = "en";
        fetch(`${process.env.REACT_APP_API_URL}/posts/deleteBy/` + postId + "?language=" + currentLanguage)
            .then(response => response.text())
            .then(data => {
                window.alert(data);
                setSureToDeleteThisPost(false);
                navigate(`/`);
            })
            .catch(error => console.log(error));
    }

    const isMobile = window.innerWidth <= 650;

    const handleDeleteAllCommentsOfThePost = () => {
      let language = "en";
      fetch(`${process.env.REACT_APP_API_URL}/comment/delete/root/comment?postId=` + postId + "&language=" + language)
        .then(response => response.text())
        .then(data => {
          window.alert(data);
          // In order to relfect those changes immediately,we fetch those comment data again.
          fetch(`${process.env.REACT_APP_API_URL}/comment/get/by/` + postId)
            .then(response => response.json())
            .then(data => setArticleComments(data))
            .catch(error => console.log(error));
        })
        .catch(error => console.log(error));
    };

    // window.alert(article.title);
    return(
        <div style={{ backgroundColor:"white" }}>
            <Helmet>
                <title>Passion Class</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    
                </Typography>
                
                </Stack>
                
                <Grid xs={12} sm={6} style={{
                    backgroundColor:"#ffffff",
                    fontSize:"20px",
                    lineHeight:"35px"}}>
                    <div style={{position:"relative"}}>
                       
                        {
                            localStorage.getItem("userLoggingInfo")
                            && <div style={{position:"absolute", top:"-27px"}}>
                                    {/* <Button
                                    size="small"
                                      variant="contained" 
                                      component="label"  
                                      onClick={handleReturnBack}
                                      style={{marginRight:"10px"}}
                                      startIcon={<ReplyIcon />}
                                      >
                                      Back
                                    </Button> */}
                                    <Button 
                                    size="small"
                                        variant="contained" 
                                        component="label"  
                                        onClick={handleJumpToEditPage}
                                        >
                                        Edit
                                    </Button>
                                    <Button
                                    size="small"
                                        variant="contained"
                                        component="label"
                                        onClick={handleDeletePostConfirmDialog}
                                        style={{marginLeft:"10px"}}
                                        >
                                        Delete
                                    </Button>
                                </div>
                            ||  <></>
                              
                        }
                        {
                            sureToDeleteThisPost
                            && <div>
                                <Dialog
                                  open={sureToDeleteThisPost}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                  style={{marginBottom:"30vh"}}
                                >
                                  <DialogTitle id="alert-dialog-title">
                                  Delete a post
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                    Are you sure to delete this post?This action will delete all its translations as well.
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleDeletePost}>Confirm</Button>
                                    <Button autoFocus onClick={handleCancellingDelete}>Cancel</Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            || <></>
                        }
                        <div className="title" style={{
                          fontSize: isMobile ? "28px" : "52px",
                          lineHeight: isMobile ? "40px" : "60px",
                          marginTop:isMobile ? "-26px" : "50px"
                          }}
                        >
                            {article.title}
                        </div>
                        
                        <div className="subTitle" 
                          style={{
                            fontSize: isMobile ? "13px" : "16px",
                            display: "flex",
                            // border:"solid red 1px",
                            alignItems:"center",
                            justifyContent:"center",
                            position:"relative"
                          }}
                        >
                          {/* article.coachName + " " + day + " " + month + " " + year  */}
                          <div style={{
                            // border:"solid black 1px",
                            paddingBottom:"10px"
                            
                          }}>
                            <StyledAvatar 
                              alt={article.coachName}
                              src={article.coachImagePath}
                              sx={{bottom:16}}
                              // style={{border:"solid red 1px"}}
                            />
                          </div>
                          <div style={{
                            fontWeight:"bolder",
                            marginLeft:"10px",
                            marginTop:"5px",
                            fontSize:isMobile ? "14px" : "20px"
                            // border:"solid red 1px"
                          }}>
                            With {article.coachName}
                          </div>
                        </div>
                        

                        <div style={{margin:"20px 0px"}}>
                            <img className="coverImg" src={article.coverImagePath} />
                        </div>
                        <div style={{fontSize: isMobile ? "14px" : "22px",lineHeight: isMobile ? "26px" : "32px"}}>
                            
                             <ReactMarkdown
                                children={article.content}
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    h1:HeaderOneRender,
                                    h4:HeaderFourRender,
                                    img: ImageRenderer,
                                    a: LinkRenderer,
                                    code : CodeRenderer
                                }}
                            />
                        </div>

                        {/* Button for purchasing this course */}
                        <div>
                          <Button 
                            variant="contained" 
                            size={isMobile? "small" : "large"} 
                            className="breathing-button"
                            onClick={() => setOpenCheckoutDialog(true)}
                          >
                            Book Your Seat
                          </Button>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div style={{border:"solid 3px #9c9a9a",borderRadius:"4px"}}></div>

                        {
                          <Dialog 
                            open={openCheckoutDialog}
                            // onClose={handleCloseCheckoutDialog} We don't want the user to lose precious process if the user accidentally clicks somewhere outside the dialog, so we don't provide this function.
                            // If the user wants to close the dialog, the user has to manually click the cancel button below.
                          >
                            <DialogTitle>
                            Book PassionClass
                            </DialogTitle>

                            <DialogContent>
                            <TextField
                                id="titleofpassionclass"
                                label="Title of PassionClass"
                                value={article.title}
                                fullWidth
                                readonly
                                style={{margin:"10px auto"}}
                                size={isMobile ? "small" : "normal"}
                            />

                            <TextField 
                              id="coachname"
                              label="Teacher"
                              value={article.coachName}
                              readonly
                              fullWidth
                              style={{margin:"10px auto"}}
                              size={isMobile ? "small" : "normal"}
                            />

                            
                            {
                              article.coursePrice &&
                              <TableContainer component={Paper}>
                                <Table aria-label="simple table" size={isMobile? "small" : "normal"}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        Course Price {isMobile ? "" : "( USD )"}
                                        </TableCell>
                                      <TableCell>
                                        PassionClass Fee {isMobile ? "" : "( USD )"}
                                        </TableCell>
                                      <TableCell>
                                        Total Price {isMobile ? "" : "( USD )"}
                                        </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                      <TableCell align={isMobile ? "left" : "center"}>{"$" + article.coursePrice.toFixed(2)}</TableCell>
                                      <TableCell align={isMobile ? "left" : "center"}>{"$" + (article.coursePrice * 0.1767).toFixed(2)}</TableCell>
                                      <TableCell align={isMobile ? "left" : "center"} style={{fontWeight:"bolder"}}>{"$" + (article.coursePrice * 1.1767).toFixed(2)}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            }
                            



                            <div style={{display:"flex",fontSize: isMobile ? "3px" : "12px", position:"relative"}}>
                  
                              <TextField
                                  id="promotinocode"
                                  label={isMobile ? "Promotion Code" : "Promotion Code ( Optional )"}
                                  type="text"
                                  value={promotionCode}
                                  onChange={(e) => setPromotionCode(e.target.value)}
                                  fullWidth
                                  style={{margin:"10px auto"}}
                                  size={isMobile ? "small" : "normal"}
                              />
                              <div style={{
                                position:"absolute", 
                                right:isMobile? "5px" : "10px", 
                                top:isMobile? "15px" : "20px",
                                }}>
                                <Button variant="contained" size={isMobile? "small" : "normal"}>
                                  Apply
                                </Button>
                              </div>
                              
                            </div>


                            <TextField 
                              id="userrealname"
                              label="Your Name"
                              variant="standard"
                              value={userRealName}
                              onChange={(e) => setUserRealName(e.target.value)}
                              fullWidth
                              style={{margin:"10px auto"}}
                              size={isMobile ? "small" : "normal"}
                            />

                            <TextField 
                              id="useremailaddress"
                              label="Your Email Address"
                              variant="standard"
                              value={userEmailAddress}
                              onChange={(e) => setUserEmailAddress(e.target.value)}
                              fullWidth
                              style={{margin:"10px auto"}}
                              size={isMobile ? "small" : "normal"}
                            />

                            <div style={{
                              // border:"solid red 1px",
                              display:"flex",
                              marginTop:"10px"
                            }}>
                              <div style={{
                              // border:"solid red 1px",
                              display:"flex",
                              alignItems:"center",
                            }}>
                                <Checkbox 
                                  checked={checkCheckbox} 
                                  onClick={() => setCheckCheckbox(!checkCheckbox)}
                                />
                              </div>
                              <div style={{
                              // border:"solid red 1px",
                              fontSize:isMobile? "11px" : ""
                            }}>
                                I agree to the <a style={{color:"blue",fontWeight:"bolder"}}>Terms of Use</a> and the <a style={{color:"blue",fontWeight:"bolder"}}>Privacy Policy</a>.
                                I consent to PassionClass's collection and processing of the provided information.
                              </div>
                            </div>

                            <div style={{border:"solid red 1px", borderRadius:"10px", marginTop:"10px"}}></div>

                            <div style={{paddingTop:"10px", fontSize:isMobile? "11px" : ""}}>
                            We collect your name and email during the purchase process and store it up to 60 days to help complete purchase. If you purchase the course, we will keep them for 10 years to be able provide proof of participation in a course, but you can contact us to request removal. Your information will not be used for marketing purposes without your consent.
                            </div>

                            </DialogContent>
                            

                            <DialogActions>
                              <Button variant="contained" onClick={handleCloseCheckoutDialog}>
                                Cancel
                              </Button>
                              <Button variant="contained" disabled={userRealName === "" || userEmailAddress === "" || !checkCheckbox }>
                                Continue
                              </Button>
                            </DialogActions>

                          </Dialog>
                        }


                        {
                              <Dialog
                              open={openDeleteAllCommentsDialog}
                              onClose={() => {setOpenDeleteAllCommentsDialog(false);}}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description">
                                <DialogTitle id="alert-dialog-title">
                                  {t('DeleteCommentsDialog')}
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    {t('ConfirmDeleteAllComments')}
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={() => {
                                    handleDeleteAllCommentsOfThePost();
                                    // After successfully deleted the posts,close the dialog
                                    setOpenDeleteAllCommentsDialog(false);
                                  }}>{t('confirm')}</Button>
                                  <Button onClick={() => {setOpenDeleteAllCommentsDialog(false);}} autoFocus>
                                  {t('cancel')}
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            }
                        <div style={{marginTop:"40px"}}>
                          <div style={{marginBottom:"3px"}}>
                            
                              <div style={{
                                // border:"solid blue 1px"
                              }}>
                                <TextField
                                  id="outlined-textarea"
                                  label={t('LeaveComment')}
                                  placeholder=""
                                  multiline
                                  variant="outlined"
                                  fullWidth
                                  maxRows={4}
                                  // style={
                                  //   isMobile
                                  //     ? {width:"95%",margin:"10px"}
                                  //     : (localStorage.getItem("userLoggingInfo") && Object.keys(articleComments).length > 6)
                                  //       ? {width: "70%",margin:"10px"}
                                  //       : {width: "81%",margin:"10px"}}
                                  onChange={(e) => {setRootComment(e.target.value);}}
                                  // If don't set the value,data flow is a single trip,
                                  // can flow only from <TextField> to rootComment and can't flow back
                                  // That means if we set rootComment to empty in order to clear the <TextField>
                                  // The clearing thing won't be reflected to <TextField> and those content will still there
                                  value={rootComment}
                                />
                              </div>
                            
                              <div style={{
                                // border:"solid black 1px",
                                display:"flex",
                                justifyContent:"flex-end",
                                marginTop:!isMobile ? "8px" : "",
                              }}>

                                {
                                  (localStorage.getItem("userLoggingInfo") && Object.keys(articleComments).length > 6)
                                  ? <div style={{
                                      // border:"solid red 1px"
                                    }}>
                                      <Button 
                                        variant="contained"
                                        size={ isMobile ? "small" : ""}
                                        // style={isMobile ? {position:"relative",right: "-26vw"} :{margin:"20px 10px"}}
                                        // onClick={handleDeleteAllCommentsOfThePost}
                                        onClick={() => {setOpenDeleteAllCommentsDialog(true);}}
                                        >
                                        {t('deleteall')}
                                      </Button>
                                    </div>
                                  : <></>
                                }
                                
                                <div style={{
                                  // border:"solid red 1px",
                                  marginLeft:"5px"
                                }}>
                                  <Button 
                                  variant="contained" 
                                  size= {isMobile ? "small" : ""}
                                  // style={isMobile 
                                  //   ? (localStorage.getItem("userLoggingInfo") && Object.keys(articleComments).length > 6) 
                                  //     ? {margin:"10px 5px",position:"relative", right:"-27vw"} 
                                  //     : {margin:"5px 5px 13px 5px",position:"relative", right:"-40vw"} 
                                  //   : {margin:"20px 10px"}}
                                  // onClick={() => {setRootComment((prevState) => ({rootComment: '' }));}}
                                  onClick={handleClearingRootComment}
                                  >
                                    {t('cancel')}
                                  </Button>
                                </div>

                                

                                <div style={{
                                  // border:"solid red 1px",
                                  marginLeft:"5px"
                                }}>
                                  <Button 
                                    variant="contained" 
                                    size= {isMobile ? "small" : ""}
                                    disabled={rootComment.trim() === ''}
                                    onClick={handlePostingRootComments}
                                    // style={isMobile 
                                    //   ? (localStorage.getItem("userLoggingInfo") && Object.keys(articleComments).length > 6) 
                                    //     ? {margin:"10px 5px",position:"relative", right:"-27vw"} 
                                    //     : {margin:"5px 5px 13px 5px",position:"relative", right: "-40vw"} 
                                    //   : {margin:"20px 10px"}}
                                    >
                                    {t('Reply')}
                                  </Button>
                                </div>

                              </div>
                              
                          </div>


                          {
                            selectedComment
                              ? <div style={{border:"solid green 2px",backgroundColor:"#edeff1"}}>
                                  <div>
                                    <Button
                                      style={{border:"solid 1px brown"}}
                                      onClick={handleBackButtonClick}
                                    >
                                      {t('Back')}
                                    </Button>
                                  </div>
                                  <div>
                                    <TreeView
                                      aria-label="rich object"
                                      defaultCollapseIcon={<ExpandMoreIcon />}
                                      defaultExpanded={rootIdArray} // expand all nodes in the tree view
                                      // must manually update the value every time you update the category.
                                      defaultExpandIcon={<ChevronRightIcon />}
                                      sx={{flexGrow: 1, minWidth: 300, overflowY: 'auto' }} 
                                      style={{paddingBottom:"10px"}}
                                    >
                                      {renderSelectedCommentMobile(selectedComment)}
                                    </TreeView>
                                  </div>
                                </div>
                              : <></>
                          }
                         
                          <div>
                            <TreeView
                              aria-label="rich object"
                              defaultCollapseIcon={<ExpandMoreIcon />}
                              defaultExpanded={rootIdArray} // expand all children of the root in the tree view
                              // expanded={idToExpandArray}
                              defaultExpandIcon={<ChevronRightIcon />}
                              sx={{flexGrow: 1, minWidth: 300, overflowY: 'auto' }} 
                              style={{paddingBottom:"10px"}}
                            >
                              {renderTree(articleComments)}
                            </TreeView>
                          </div>
                      
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                </Grid>
            </Container>
        </div>
    );
}






const HeaderOneRender = ({ children }) => {
    const isMobile = window.innerWidth <= 650;
    return(
        <h1 style={{fontSize: isMobile ? '22px' : '30px', marginBottom:"0px"}}>{ children }</h1>
    );
};

const HeaderFourRender = ({ children }) => {
  const isMobile = window.innerWidth <= 650;
  return(
      <h4 style={{
        fontSize: isMobile ? '15px' : '24px', 
        marginBottom:isMobile ? "-40px" : "-45px",
      }}
      >
        { children }
      </h4>
  );
};





// customized rendering for images
const ImageRenderer = ({ src, alt }) => (
    <img src={src} alt={alt} style={{ width: '100%', height: 'auto' }} />
  );

// Customized rendering for hyper links
const LinkRenderer = ({ children, href }) => {
    return (
      <a
        href={href}
        target="_blank"
        style={{
          fontWeight:"bolder",
          color: '#3eb0ef',
          textDecoration: 'none', // Remove underline if desired
        }}
        onMouseEnter={(e) => {
          e.target.style.color = '#1e7ca8'; // Darker color on hover
          e.target.style.textDecoration = 'underline';
        }}
        onMouseLeave={(e) => {
          e.target.style.color = '#3eb0ef'; // Restore original color when not hovering
          e.target.style.textDecoration = 'none';
        }}
      >
        {children}
      </a>
    );
  };

// CodeRenderer component
const CodeRenderer = ({ node, inline, className, children, ...props }) => {
    const [isCopied, setIsCopied] = useState(false);
    const match = /language-(\w+)/.exec(className || '');
    const isMobile = window.innerWidth <= 650;
  
    const handleCopy = () => {
      navigator.clipboard.writeText(children);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    };

    
    if (inline) {
        return (
          <div style={isMobile ? {fontSize:"12px",display:"inline-block",marginLeft:"5px",marginRight:"5px",position:"relative",top:"0px",borderRadius:"5px"} : {fontSize:"16px",display:"inline-block",marginLeft:"5px",marginRight:"5px",position:"relative",top:"-3px",borderRadius:"5px"}}>
            <div style={isMobile ? { overflow: "clip", height: "23px",borderRadius:"5px",display: "flex", alignItems: "center" } : { overflow: "hidden", height: "30px",borderRadius:"5px",display: "flex", alignItems: "center"}}>
                <SyntaxHighlighter
                    {...props}
                    style={{ ...nord}}
                    language="vim"
                    PreTag="span"
                    >
                    {children}
                </SyntaxHighlighter>
            </div>
          </div>
        );
      }
  
    return !inline && match ? (
        // If we use calssName to set the style , it won't work,probably it's been overridden by some other settings
        // Since inline style has higher specificity than class-based styles
      <div style={{
        position: 'relative',
        paddingTop:"26px",
        fontSize:isMobile ? "12px" : "",
        lineHeight:isMobile ? "16px" : "",
        marginTop:"-15px"
        // border:"solid red 1px"
      }}
      >
        <SyntaxHighlighter
          {...props}
          children={String(children).replace(/\n$/, '')}
          style={nord}
          language={match[1]}
          PreTag="div"
        />
        <Button 
           size="small"
            variant={isMobile ? "contained" : "contained"} 
            component="label"  
            onClick={handleCopy} 
            style={ isMobile ? {fontSize:"11px",position: 'absolute', top: 0, right: 0 } : {position: 'absolute', top: 0, right: 0 }}
            >
          {isCopied ? 'Copied!' : 'Copy'}
        </Button>
      </div>
    ) : (
      <code {...props} className={className}>
        {children}
      </code>
        
    );
  };

